import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'

import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { Heading } from 'components/UserResearchPanel/CommonInputs/Heading'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'
import { TextInput } from 'components/UserResearchPanel/CommonInputs/TextInput'
import {
    AVALANCHE_FORECAST_CHECKED_FREQUENCY,
    AVALANCHE_FORECAST_CHECKED_FREQUENCY_OPTIONS,
    AVALANCHE_FORECAST_USAGE,
    AVALANCHE_FORECAST_USAGE_OPTIONS,
    DECISION_MAKING_CONTRIBUTION,
    DECISION_MAKING_CONTRIBUTION_OPTIONS,
    GPS_PLATFORM_OPTIONS,
    GPS_TRACKING_PLATFORM_OTHER,
    GPS_TRACKING_PLATFORMS,
    INFORMATION_SOURCES_CONSULTED,
    INFORMATION_SOURCES_CONSULTED_OPTIONS,
    INFORMATION_SOURCES_CONSULTED_OTHER,
    motivationMessages,
    REGIONAL_AVALANCHE_FORECAST,
    SAFETY_EQUIPMENT_TYPICALLY_USED,
    SAFETY_EQUIPMENT_TYPICALLY_USED_OPTIONS,
    TRACK_WITH_GPS,
} from 'components/UserResearchPanel/SignupSteps/Motivations/MotivationConstants'
import { RankedMotivations } from 'components/UserResearchPanel/SignupSteps/Motivations/RankedMotivations'
import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'

export const Motivations = () => {
    const { formatMessage } = useIntl()

    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()

    const handleInformationSourcesConsultedChange = (option: string) => {
        const newValue = Array.isArray(formData[INFORMATION_SOURCES_CONSULTED])
            ? formData[INFORMATION_SOURCES_CONSULTED].includes(option)
                ? formData[INFORMATION_SOURCES_CONSULTED].filter(item => item !== option)
                : [...(formData[INFORMATION_SOURCES_CONSULTED] as string[]), option]
            : [option]

        handleChange(INFORMATION_SOURCES_CONSULTED, newValue)
    }

    const handleSafetyEquipmentTypicallyUsedChange = (option: string) => {
        const newValue = Array.isArray(formData[SAFETY_EQUIPMENT_TYPICALLY_USED])
            ? formData[SAFETY_EQUIPMENT_TYPICALLY_USED].includes(option)
                ? formData[SAFETY_EQUIPMENT_TYPICALLY_USED].filter(item => item !== option)
                : [...(formData[SAFETY_EQUIPMENT_TYPICALLY_USED] as string[]), option]
            : [option]

        handleChange(SAFETY_EQUIPMENT_TYPICALLY_USED, newValue)
    }

    const handleGPSPlatformChange = (option: string) => {
        const newValue = Array.isArray(formData[GPS_TRACKING_PLATFORMS])
            ? formData[GPS_TRACKING_PLATFORMS].includes(option)
                ? formData[GPS_TRACKING_PLATFORMS].filter(item => item !== option)
                : [...(formData[GPS_TRACKING_PLATFORMS] as string[]), option]
            : [option]

        handleChange(GPS_TRACKING_PLATFORMS, newValue)
    }

    const showOtherInformationSources =
        Array.isArray(formData[INFORMATION_SOURCES_CONSULTED]) &&
        formData[INFORMATION_SOURCES_CONSULTED].includes(INFORMATION_SOURCES_CONSULTED_OTHER)
    const avalancheForecastSelected =
        Array.isArray(formData[INFORMATION_SOURCES_CONSULTED]) &&
        formData[INFORMATION_SOURCES_CONSULTED].includes(REGIONAL_AVALANCHE_FORECAST)
    const trackWithGPS = formData[TRACK_WITH_GPS]
    const showOtherGPSPlatform =
        (trackWithGPS as boolean) &&
        Array.isArray(formData[GPS_TRACKING_PLATFORMS]) &&
        formData[GPS_TRACKING_PLATFORMS].includes(GPS_TRACKING_PLATFORM_OTHER)

    return (
        <>
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Motivations" />
            </Heading>
            <RankedMotivations />
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Decision Making" />
            </Heading>
            <FormControl
                sx={styles.formControl}
                error={touched[DECISION_MAKING_CONTRIBUTION] && !!errors[DECISION_MAKING_CONTRIBUTION]}
            >
                <QuestionText required>
                    <FormattedMessage
                        defaultMessage="Which of the following statements best describes how you typically contribute to the decision of when and where to go recreating in the backcountry and any avalanche risk management decision in the field?"
                        description="User Research Panel"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectOneOption} />
                </FormHelperText>
                <RadioGroup
                    value={formData[DECISION_MAKING_CONTRIBUTION] || ''}
                    onChange={e => {
                        handleChange(DECISION_MAKING_CONTRIBUTION, e.target.value)
                    }}
                    aria-invalid={touched[DECISION_MAKING_CONTRIBUTION] && !!errors[DECISION_MAKING_CONTRIBUTION]}
                >
                    {DECISION_MAKING_CONTRIBUTION_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option}
                            value={option}
                            control={<Radio />}
                            label={
                                <FormattedMessage {...motivationMessages[option as keyof typeof motivationMessages]} />
                            }
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Resources Used" />
            </Heading>
            <FormControl
                sx={styles.formControl}
                error={touched[INFORMATION_SOURCES_CONSULTED] && !!errors[INFORMATION_SOURCES_CONSULTED]}
                aria-invalid={touched[INFORMATION_SOURCES_CONSULTED] && !!errors[INFORMATION_SOURCES_CONSULTED]}
            >
                <QuestionText required>
                    <FormattedMessage
                        defaultMessage="When planning a backcountry trip, which of the following information sources do you typically consult for getting an understanding of the current avalanche conditions?"
                        description="User Research Panel"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                <Box sx={styles.flex}>
                    {INFORMATION_SOURCES_CONSULTED_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option}
                            control={
                                <Checkbox
                                    checked={
                                        Array.isArray(formData[INFORMATION_SOURCES_CONSULTED])
                                            ? formData[INFORMATION_SOURCES_CONSULTED].includes(option)
                                            : false
                                    }
                                    onChange={() => handleInformationSourcesConsultedChange(option)}
                                />
                            }
                            label={
                                <FormattedMessage {...motivationMessages[option as keyof typeof motivationMessages]} />
                            }
                        />
                    ))}
                </Box>
                {showOtherInformationSources && (
                    <TextInput
                        dataKey={INFORMATION_SOURCES_CONSULTED_OTHER}
                        placeholder={formatMessage(motivationMessages.informationSourcesConsultedOther)}
                        sx={{ mt: 2 }}
                    />
                )}
            </FormControl>
            {avalancheForecastSelected && (
                <>
                    <FormControl
                        sx={styles.formControl}
                        error={
                            touched[AVALANCHE_FORECAST_CHECKED_FREQUENCY] &&
                            !!errors[AVALANCHE_FORECAST_CHECKED_FREQUENCY]
                        }
                        aria-invalid={
                            touched[AVALANCHE_FORECAST_CHECKED_FREQUENCY] &&
                            !!errors[AVALANCHE_FORECAST_CHECKED_FREQUENCY]
                        }
                    >
                        <QuestionText required>
                            <FormattedMessage
                                defaultMessage="How often do you use the avalanche forecast to check avalanche conditions?"
                                description="User Research Panel"
                            />
                        </QuestionText>
                        <FormHelperText>
                            <FormattedMessage {...commonMessages.selectOneOption} />
                        </FormHelperText>
                        <RadioGroup
                            value={formData[AVALANCHE_FORECAST_CHECKED_FREQUENCY] || ''}
                            onChange={e => {
                                handleChange(AVALANCHE_FORECAST_CHECKED_FREQUENCY, e.target.value)
                            }}
                            aria-invalid={
                                touched[AVALANCHE_FORECAST_CHECKED_FREQUENCY] &&
                                !!errors[AVALANCHE_FORECAST_CHECKED_FREQUENCY]
                            }
                        >
                            {AVALANCHE_FORECAST_CHECKED_FREQUENCY_OPTIONS.map(option => (
                                <FormControlLabel
                                    key={option}
                                    value={option}
                                    control={<Radio />}
                                    label={
                                        <FormattedMessage
                                            {...motivationMessages[option as keyof typeof motivationMessages]}
                                        />
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                    <FormControl sx={styles.formControl}>
                        <QuestionText required>
                            <FormattedMessage
                                defaultMessage="Which of the following statements best describes your use of the avalanche forecast when planning a backcountry trip?"
                                description="User Research Panel"
                            />
                        </QuestionText>
                        <FormHelperText error={touched[AVALANCHE_FORECAST_USAGE] && !!errors[AVALANCHE_FORECAST_USAGE]}>
                            <FormattedMessage {...commonMessages.selectOneOption} />
                        </FormHelperText>
                        <RadioGroup
                            value={formData[AVALANCHE_FORECAST_USAGE] || ''}
                            onChange={e => {
                                handleChange(AVALANCHE_FORECAST_USAGE, e.target.value)
                            }}
                            aria-invalid={touched[AVALANCHE_FORECAST_USAGE] && !!errors[AVALANCHE_FORECAST_USAGE]}
                        >
                            {AVALANCHE_FORECAST_USAGE_OPTIONS.map(option => (
                                <FormControlLabel
                                    sx={styles.radioGroupWithLongText}
                                    key={option}
                                    value={option}
                                    control={<Radio />}
                                    label={
                                        <FormattedMessage
                                            {...motivationMessages[option as keyof typeof motivationMessages]}
                                        />
                                    }
                                />
                            ))}
                        </RadioGroup>
                    </FormControl>
                </>
            )}
            <FormControl
                sx={styles.formControl}
                error={touched[SAFETY_EQUIPMENT_TYPICALLY_USED] && !!errors[SAFETY_EQUIPMENT_TYPICALLY_USED]}
            >
                <QuestionText required>
                    <FormattedMessage
                        defaultMessage="Which of the following safety equipment items do you typically bring into the backcountry in the winter?"
                        description="User Research Panel"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                <Box sx={styles.flex}>
                    {SAFETY_EQUIPMENT_TYPICALLY_USED_OPTIONS.map(option => (
                        <FormControlLabel
                            key={option}
                            value={option}
                            control={
                                <Checkbox
                                    checked={
                                        Array.isArray(formData[SAFETY_EQUIPMENT_TYPICALLY_USED])
                                            ? formData[SAFETY_EQUIPMENT_TYPICALLY_USED].includes(option)
                                            : false
                                    }
                                    onChange={() => handleSafetyEquipmentTypicallyUsedChange(option)}
                                    inputProps={{
                                        'aria-invalid':
                                            touched[SAFETY_EQUIPMENT_TYPICALLY_USED] &&
                                            !!errors[SAFETY_EQUIPMENT_TYPICALLY_USED],
                                    }}
                                />
                            }
                            label={formatMessage(motivationMessages[option as keyof typeof motivationMessages])}
                        />
                    ))}
                </Box>
            </FormControl>
            <FormControl sx={styles.formControl}>
                <QuestionText required>
                    <FormattedMessage
                        defaultMessage="Do you use a GPS tracking device to track your location in the backcountry?"
                        description="User Research Panel"
                    />
                </QuestionText>
                <RadioGroup
                    value={String(formData[TRACK_WITH_GPS])}
                    onChange={e => {
                        handleChange(TRACK_WITH_GPS, e.target.value)
                    }}
                    aria-invalid={touched[TRACK_WITH_GPS] && !!errors[TRACK_WITH_GPS]}
                >
                    <FormControlLabel value="true" control={<Radio />} label={formatMessage(motivationMessages.yes)} />
                    <FormControlLabel value="false" control={<Radio />} label={formatMessage(motivationMessages.no)} />
                </RadioGroup>
                {touched[TRACK_WITH_GPS] && !!errors[TRACK_WITH_GPS] && (
                    <FormHelperText error>{errors[TRACK_WITH_GPS]}</FormHelperText>
                )}
            </FormControl>
            {Boolean(trackWithGPS) && (
                <FormControl
                    sx={styles.formControl}
                    error={touched[GPS_TRACKING_PLATFORMS] && !!errors[GPS_TRACKING_PLATFORMS]}
                    aria-invalid={touched[GPS_TRACKING_PLATFORMS] && !!errors[GPS_TRACKING_PLATFORMS]}
                >
                    <QuestionText required>
                        <FormattedMessage
                            defaultMessage="Select all platforms you use to share GPS tracks and trip reports"
                            description="User Research Panel"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                    </FormHelperText>
                    <Box sx={styles.flex}>
                        {GPS_PLATFORM_OPTIONS.map(option => (
                            <FormControlLabel
                                key={option}
                                value={option}
                                control={
                                    <Checkbox
                                        checked={
                                            Array.isArray(formData[GPS_TRACKING_PLATFORMS]) &&
                                            formData[GPS_TRACKING_PLATFORMS].includes(option)
                                        }
                                        onChange={() => handleGPSPlatformChange(option)}
                                    />
                                }
                                label={
                                    <FormattedMessage
                                        {...motivationMessages[option as keyof typeof motivationMessages]}
                                    />
                                }
                            />
                        ))}
                    </Box>
                    {showOtherGPSPlatform && (
                        <TextInput
                            dataKey={GPS_TRACKING_PLATFORM_OTHER}
                            placeholder={formatMessage(motivationMessages.gpsTrackingPlatformOther)}
                            sx={{ mt: 2 }}
                        />
                    )}
                </FormControl>
            )}
        </>
    )
}

const styles = {
    formControl: {
        width: '100%',
    },
    marginTop: {
        marginTop: '2em',
    },
    radioGroupWithLongText: {
        marginBottom: '0.8em',
    },
    flex: {
        display: 'flex' as const,
        gap: '1px',
        flexDirection: 'column' as const,
        flexWrap: 'wrap' as const,
    },
}
