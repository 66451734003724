import { ReactNode } from 'react'

export const Heading = ({ children }: { children: ReactNode }) => {
    return <h2 style={styles}>{children}</h2>
}

const styles = {
    margin: '1em 0 0.75em',
    fontSize: '1.5em',
    lineHeight: 1.2,
    color: '#2d2d2d',
}
