import { defineMessages } from 'react-intl'

export const WEEKDAYS_IN_BACKCOUNTRY_WEEKENDS_AND_STATUTORY_HOLIDAYS = 'weekends_and_statutory_holidays'
export const WEEKDAYS_IN_BACKCOUNTRY_WINTER_VACATIONS = 'winter_vacations'
export const WEEKDAYS_IN_BACKCOUNTRY_REGULAR_WEEKDAYS = 'regular_weekdays'

export const WEEKDAYS_IN_BACKCOUNTRY_OPTIONS = [
    WEEKDAYS_IN_BACKCOUNTRY_WEEKENDS_AND_STATUTORY_HOLIDAYS,
    WEEKDAYS_IN_BACKCOUNTRY_WINTER_VACATIONS,
    WEEKDAYS_IN_BACKCOUNTRY_REGULAR_WEEKDAYS,
] as const

// Note that these keys are the same as the constants defined above, but React i18n won't allow dynamic key definitions.
export const weekDaysInBackcountryMessages = defineMessages({
    weekends_and_statutory_holidays: {
        id: 'UserResearchPanel.BackcountryActivities.weekends_and_statutory_holidays',
        defaultMessage: 'Weekends and statutory holidays',
    },
    winter_vacations: {
        id: 'UserResearchPanel.BackcountryActivities.winter_vacations',
        defaultMessage: 'Winter vacations',
    },
    regular_weekdays: {
        id: 'UserResearchPanel.BackcountryActivities.regular_weekdays',
        defaultMessage: 'Regular weekdays',
    },
})
