import Prismic from '@prismicio/client'
import EN, { FR, ALL_LANGUAGES } from '@avcan/constants/locales'

// Custom logger with request counter
// const apiLogger = {
//     requestCount: 0,
//     log: (...args) => {
//         apiLogger.requestCount++
//         console.log(
//             `🌐 [PRISMIC ${new Date().toISOString()}] Request #${apiLogger.requestCount}:`,
//             ...args
//         )
//     },
//     error: (...args) => console.error(`❌ [PRISMIC ${new Date().toISOString()}]`, ...args),
// }

export default class Client {
    constructor(api) {
        try {
            this.client = Prismic.client(api)
        } catch (error) {
            console.error('Failed to initialize Prismic client:', error)

            this.client = {
                query: async () => ({ results: [] }),
                queryFirst: async () => null,
            }
        }
    }
    async query(predicates, { locale = EN, ...options } = {}) {
        try {
            if (locale === FR || locale === ALL_LANGUAGES) {
                // Not all Prismic documents have been translated, so
                // we are loading documents for all languages and filter
                // to the language afterward. In case a given document has not
                // been translated, the English one will be returned
                options.lang = ALL_LANGUAGES
            }

            // apiLogger.log('Query predicates:', predicates)

            const payload = await this.client.query(predicates, options)

            if (locale === EN || locale === ALL_LANGUAGES) {
                return payload
            }

            return {
                ...payload,
                // Filter results to only include the requested language
                results: payload.results.filter(({ lang, alternate_languages }) => {
                    const language = LANGUAGES.get(locale)

                    if (lang === language) {
                        return true
                    }

                    return alternate_languages.length === 0 // Meanning this is the only document available, so it needs to be includes!
                }),
            }
        } catch (error) {
            console.error('Error querying Prismic:', error, predicates, options)
            return { results: [] }
        }
    }
    async queryFirst(predicates, options) {
        try {
            const { results } = await this.query(predicates, options)
            return results[0] || null
        } catch (error) {
            console.error('Error in queryFirst:', error, predicates, options)
            return null
        }
    }
}

// Constants
const LANGUAGES = new Map([
    [EN, 'en-us'],
    [FR, 'fr-ca'],
])
