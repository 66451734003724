import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Heading } from 'components/UserResearchPanel/CommonInputs/Heading'
import { INFORMED_CONSENT } from 'components/UserResearchPanel/validationSchema'
import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'

export const Consent = () => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()

    return (
        <div>
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Informed Consent" />
            </Heading>
            <Typography variant="body1">
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="By agreeing to participate, you will stay informed about new studies on an ongoing basis. Your
                    participation in this initiative and any forthcoming studies is entirely voluntary. You can withdraw at
                    any time by sending an email to <a>research@avalanche.ca</a>."
                        values={{
                            a: chunks => <a href="mailto:research@avalanche.ca">{chunks}</a>,
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Taking part in this research panel is considered low risk as the research does not expose participants
                    to any known or foreseeable risks. Consenting to participate in research does not waive any rights to
                    legal recourse in the event of research-related harm. Each individual study will include its own
                    informed consent process and will be entirely voluntary."
                    />
                </p>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Do you consent to participate in the research panel and receive information about new studies on an
                    ongoing basis, while part of this initiative?"
                    />
                </p>
            </Typography>
            <FormControl>
                <RadioGroup
                    name={INFORMED_CONSENT}
                    value={String(formData[INFORMED_CONSENT])}
                    onChange={e => handleChange(INFORMED_CONSENT, e.target.value)}
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="Yes, I consent to participate"
                            />
                        }
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="I do not consent to participate"
                            />
                        }
                    />
                </RadioGroup>
                {touched[INFORMED_CONSENT] && errors[INFORMED_CONSENT] && (
                    <FormHelperText error>{errors[INFORMED_CONSENT]}</FormHelperText>
                )}
            </FormControl>
        </div>
    )
}
