import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormattedMessage } from 'react-intl'

import { forType, OneLiner, USER_RESEARCH_PANEL } from 'components/alert'
import { useUserResearchPanelData } from 'hooks/UseUserResearchPanelData'
import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'

import PrismicData from 'prismic/PrismicData.preval'

const DAYS_TO_HIDE_BANNER = 4

const Alert = forType(USER_RESEARCH_PANEL)

export const UserResearchPanelBanner = () => {
    const router = useRouter()

    const { displayUserResearchPanelBanner } = PrismicData
    const isPrismicOn = displayUserResearchPanelBanner?.data?.boolean

    const { data, isLoading } = useUserResearchPanelData()
    const showBanner = !isLoading && data?.data === undefined

    const { bannerLastDismissed, setBannerLastDismissed } = UserResearchPanelSignupStore()

    const isBannerHidden =
        bannerLastDismissed &&
        new Date(bannerLastDismissed).getTime() > Date.now() - 1000 * 60 * 60 * 24 * DAYS_TO_HIDE_BANNER

    if (!Alert || isBannerHidden || !showBanner || !isPrismicOn) {
        return null
    }

    return (
        <Alert onDismiss={() => setBannerLastDismissed(Date.now())}>
            <Link
                href={{
                    pathname: '',
                    query: { ...router.query, userResearchPanel: 'true' },
                }}
            >
                <OneLiner>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Join the Avalanche Canada User Research Panel and help us improve our services!"
                    />
                </OneLiner>
            </Link>
        </Alert>
    )
}
