import { defineMessages } from 'react-intl'

export const NOT_AT_ALL_IMPORTANT = 'not_at_all_important'
export const SLIGHTLY_IMPORTANT = 'slightly_important'
export const IMPORTANT = 'important'
export const VERY_IMPORTANT = 'very_important'
export const EXTREMELY_IMPORTANT = 'extremely_important'

export const IMPORTANCE_OPTIONS = [
    NOT_AT_ALL_IMPORTANT,
    SLIGHTLY_IMPORTANT,
    IMPORTANT,
    VERY_IMPORTANT,
    EXTREMELY_IMPORTANT,
] as const

export const importanceMessages = defineMessages({
    not_at_all_important: {
        description: 'User Research Panel',
        defaultMessage: 'Not at all important',
    },
    slightly_important: {
        description: 'User Research Panel',
        defaultMessage: 'Slightly important',
    },
    important: {
        description: 'User Research Panel',
        defaultMessage: 'Important',
    },
    very_important: {
        description: 'User Research Panel',
        defaultMessage: 'Very important',
    },
    extremely_important: {
        description: 'User Research Panel',
        defaultMessage: 'Extremely important',
    },
})
