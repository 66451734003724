import { defineMessages } from 'react-intl'

export const NEVER = 'never'
export const LESS_THAN_FIVE = 'less_than_five'
export const FIVE_TO_TEN = 'five_to_ten'
export const ELEVEN_TO_TWENTY = 'eleven_to_twenty'
export const TWENTY_ONE_TO_FIFTY = 'twenty_one_to_fifty'
export const MORE_THAN_FIFTY = 'more_than_fifty'

export const PROFESSIONAL_DAYS_WORKED_PER_SEASON_OPTIONS = [
    { name: NEVER },
    { name: LESS_THAN_FIVE },
    { name: FIVE_TO_TEN },
    { name: ELEVEN_TO_TWENTY },
    { name: TWENTY_ONE_TO_FIFTY },
    { name: MORE_THAN_FIFTY },
] as const

export const checkIfProfessionalDaysWorkedPerSeasonIsMoreThan10 = (daysWorkedPerSeason: string) => {
    return (
        daysWorkedPerSeason === ELEVEN_TO_TWENTY ||
        daysWorkedPerSeason === TWENTY_ONE_TO_FIFTY ||
        daysWorkedPerSeason === MORE_THAN_FIFTY
    )
}

export const professionalDaysPerWinterMessages = defineMessages({
    never: {
        id: 'UserResearchPanel.PersonalInvolvement.never',
        defaultMessage: 'Never',
        description: 'User Research Panel',
    },
    less_than_five: {
        id: 'UserResearchPanel.PersonalInvolvement.less_than_five',
        defaultMessage: 'Less than 5 days per winter',
        description: 'User Research Panel',
    },
    five_to_ten: {
        id: 'UserResearchPanel.PersonalInvolvement.five_to_ten',
        defaultMessage: '5–10 days per winter',
        description: 'User Research Panel',
    },
    eleven_to_twenty: {
        id: 'UserResearchPanel.PersonalInvolvement.eleven_to_twenty',
        defaultMessage: '11–20 days per winter',
        description: 'User Research Panel',
    },
    twenty_one_to_fifty: {
        id: 'UserResearchPanel.PersonalInvolvement.twenty_one_to_fifty',
        defaultMessage: '21–50 days per winter',
        description: 'User Research Panel',
    },
    more_than_fifty: {
        id: 'UserResearchPanel.PersonalInvolvement.more_than_fifty',
        defaultMessage: 'More than 50 days per winter',
        description: 'User Research Panel',
    },
})
