import * as React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import NextLink from 'next/link'
import { FormattedMessage, useIntl } from 'react-intl'
import { Header, Main, Content, Section } from 'components/page'
import { ButtonSet } from 'components/button'
import { Credit } from 'components/misc'
import { Mailto } from 'components/anchors'
import * as Texts from 'components/text'
import Footer, { Navigation } from '@avcan/crystal/components/footer/Footer'
import useColumns from 'constants/menus/footer'
import Navbar from 'layouts/navbars/AvalancheCanada'
import SPAW from 'layouts/products/spaw'
import Highlight from 'layouts/highlight'
import * as Mapbox from 'utils/mapbox'
import * as Emails from 'constants/emails'
import styles from './pages.module.css'
import { NotProductionAlert } from 'layouts/NotProductionAlert'
import { UserResearchPanelBanner } from 'components/UserResearchPanel/UserResearchPanelBanner'

// High level components
export function Page({ className, isArchive = false, navbar = <Navbar />, children }) {
    const columns = useColumns()

    return (
        <div className={clsx(styles.Page, className)}>
            <header>{navbar}</header>
            <div>
                <div>
                    {isArchive ? null : <SPAW />}
                    <Highlight />
                    {children}
                </div>
                <div className={styles.FooterContainer}>
                    <Footer>
                        <Navigation columns={columns} />
                    </Footer>
                </div>
            </div>
        </div>
    )
}

export function Screen({ footer, className, isArchive = false, navbar = <Navbar />, children }) {
    return (
        <div className={clsx(styles.Screen, className)}>
            <a className="visually-hidden" href="#main">
                <FormattedMessage defaultMessage="Skip to main content" description="Skip to main content" />
            </a>
            <header>
                {navbar}
                {isArchive ? null : <SPAW />}
                <Highlight />
                <UserResearchPanelBanner />
                <NotProductionAlert />
            </header>
            <main id="main">{children}</main>
            {footer}
        </div>
    )
}

// Layouts components
Error.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
}

// TODO Simplify usage of the page component, <Content> might not be required and need a nicier page.
export function Error({ children, className, ...rest }) {
    return (
        <Screen className={clsx(styles.Error, className)} {...rest} isArchive>
            {children}
            <Credit>Kroschel Films</Credit>
        </Screen>
    )
}

export function Loading({ title, headline, children = <Texts.Loading /> }) {
    title = <Texts.Loading as="span">{title}</Texts.Loading>

    return (
        <Page>
            <Header title={title} />
            <Content>
                <Main>
                    <Section headline={headline}>{children}</Section>
                </Main>
            </Content>
        </Page>
    )
}

// Aggregator components
Layout.propTypes = {
    title: PropTypes.string.isRequired,
    headline: PropTypes.node,
    children: PropTypes.node.isRequired,
}

export function Layout({ title, headline, children }) {
    return (
        <Page>
            <Header title={title} />
            <Content>
                <Main>
                    <Section headline={headline}>{children}</Section>
                </Main>
            </Content>
        </Page>
    )
}

export function UnsupportedMap() {
    return (
        <Error>
            <h1>
                <FormattedMessage
                    description="Layout pages/UnsupportedMap"
                    defaultMessage="Uh oh! You never thought that would happen..."
                />
            </h1>
            <p>
                <FormattedMessage
                    description="Layout pages/UnsupportedMap"
                    defaultMessage="It seems that your browser does not support the technology required (WebGL for the geeks) to show forecasts, advisories and other avalanche-related information on our map."
                />
            </p>
            <p>
                <FormattedMessage
                    description="Layout pages/UnsupportedMap"
                    defaultMessage="We suggest you <browser>update your browser</browser> and make sure that WebGL is <webGL>enabled</webGL>."
                    values={{
                        browser(chunks) {
                            return (
                                <a href="https://outdatedbrowser.com" target="_blank" rel="noreferrer">
                                    {chunks}
                                </a>
                            )
                        },
                        webGL(chunks) {
                            return (
                                <a href="https://get.webgl.org/" target="_blank" rel="noreferrer">
                                    {chunks}
                                </a>
                            )
                        },
                    }}
                />
            </p>
            <p>
                <FormattedMessage
                    description="Layout pages/UnsupportedMap"
                    defaultMessage="If you need help or have questions, do not hesitate to send us an <link>email</link>."
                    values={{
                        link(chunks) {
                            return <Support>{chunks}</Support>
                        },
                    }}
                />
            </p>
            <ButtonSet>
                <Link href="/forecasts">
                    <FormattedMessage description="Layout pages/UnsupportedMap" defaultMessage="Forecast regions" />
                </Link>
                <Link href="/advisories">
                    <FormattedMessage description="Layout pages/UnsupportedMap" defaultMessage="Avalanche Advisories" />
                </Link>
                <Link href="/weather/stations">
                    <FormattedMessage description="Layout pages/UnsupportedMap" defaultMessage="Weather stations" />
                </Link>
            </ButtonSet>
        </Error>
    )
}

function Support({ children }) {
    const intl = useIntl()
    const { userAgent } = navigator
    const body = `\n\n\nMapBox GL supported: ${Mapbox.supported()}\nNavigator: ${userAgent}`
    const subject = intl.formatMessage({
        description: 'Layout pages/UnsupportedMap',
        defaultMessage: 'Unsupported map',
    })

    return (
        <Mailto email={Emails.SUPPORT} subject={subject} body={body}>
            {children}
        </Mailto>
    )
}

export function Fallback({ error, children }) {
    return (
        <Error>
            <h1>
                <FormattedMessage
                    description="Layout pages/Fallback"
                    defaultMessage="Uh oh! We never thought that would happen..."
                />
                <small>
                    <FormattedMessage
                        description="Layout pages/Fallback"
                        defaultMessage="An error occurred on the page you are visiting."
                    />
                </small>
            </h1>
            <p>
                <FormattedMessage
                    description="Layout pages/Fallback"
                    defaultMessage="We have been notified about that error; we will try to fix it as soon as possible."
                />
            </p>
            <details>
                <summary>
                    <FormattedMessage defaultMessage="More details" />
                </summary>
                <Texts.Error>{error.name}</Texts.Error>
                <Texts.Error>{error.message}</Texts.Error>
            </details>
            {children}
        </Error>
    )
}

export function Link({ href, children }) {
    return (
        <NextLink href={href} className={styles.Link}>
            {children}
        </NextLink>
    )
}

export function FooterDragHandle() {
    const ref = React.useRef()

    function handleClick() {
        const distanceFromTop = window.pageYOffset

        if (distanceFromTop === 0) {
            // HACK: ref.current.scrollIntoView(true) should work but is broken in iOS Safari 15
            scrollElementIntoView(ref.current)
        } else {
            // HACK: ref.current.scrollIntoView(false) should work but is broken in iOS Safari 15
            window.scrollTo(0, 0)
        }
    }

    return <div ref={ref} className={styles.FooterDragHandle} data-theme-variant="dark" onClick={handleClick} />
}

function scrollElementIntoView(element) {
    const scrollTop = window.pageYOffset || element.scrollTop
    const offset = element.getBoundingClientRect().top + scrollTop

    window.parent.scrollTo({
        top: offset,
    })
}
