import { useCallback, useRef, useState } from 'react'

import { SxProps, TextField, Theme } from '@mui/material'

import { UserResearchPanelFormValues } from 'components/UserResearchPanel/validationSchema'
import { useHandleBlur, useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'

type TextInputProps = {
    dataKey: keyof UserResearchPanelFormValues
    placeholder: string
    fullWidth?: boolean
    sx?: SxProps<Theme>
    required?: boolean
}

export const TextInput = ({ dataKey, placeholder, fullWidth = true, sx, required = false }: TextInputProps) => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()
    const handleBlur = useHandleBlur()

    const debounceTimeout = useRef<NodeJS.Timeout>()
    const [localValue, setLocalValue] = useState(formData[dataKey as keyof typeof formData] || '')

    const debouncedHandleChange = useCallback(
        (value: string) => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current)
            }
            debounceTimeout.current = setTimeout(() => {
                handleChange(dataKey, value)
            }, 500)
        },
        [dataKey, handleChange]
    )

    return (
        <TextField
            fullWidth={fullWidth}
            required={required}
            value={localValue}
            onChange={e => {
                const newValue = e.target.value
                setLocalValue(newValue)
                debouncedHandleChange(newValue)
            }}
            onBlur={() => {
                handleBlur(dataKey, formData[dataKey as keyof typeof formData] as string)
            }}
            error={touched[dataKey as keyof typeof touched] && !!errors[dataKey as keyof typeof errors]}
            helperText={touched[dataKey as keyof typeof touched] && errors[dataKey as keyof typeof errors]}
            placeholder={placeholder}
            label={placeholder}
            sx={sx}
        />
    )
}
