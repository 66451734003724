import { Box } from '@mui/material'

const QUESTION = 'question'
const SUBQUESTION = 'subquestion'

interface QuestionTextProps {
    required?: boolean
    children: React.ReactNode
    variant?: typeof QUESTION | typeof SUBQUESTION
}

const styles = {
    questionText: {
        fontSize: '1.4rem',
        marginBottom: '0.15rem',
        color: '#333',
        lineHeight: 1.4,
        fontWeight: 500,
    },
    subquestionText: {
        fontSize: '1.2rem',
        marginBottom: '0.25rem',
        marginTop: '0.5rem',
        marginLeft: '1rem',
        color: '#333',
        lineHeight: 1.4,
    },
    container: {
        display: 'inline-block',
        width: '100%',
    },
    required: {
        color: 'red',
        marginLeft: '0.2rem',
    },
}

export const QuestionText = ({ required, children, variant = 'question' }: QuestionTextProps) => {
    return (
        <Box sx={variant === QUESTION ? styles.questionText : styles.subquestionText}>
            <div style={styles.container}>
                {children}
                {required && <span style={styles.required}>*</span>}
            </div>
        </Box>
    )
}
