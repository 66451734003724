// This file is necessary to avoid circular dependencies
import { defineMessages } from 'react-intl'

export const NAVIGATING_THROUGH_CHALLENGING_TERRAIN = 'navigatingThroughChallengingTerrain'
export const SPENDING_TIME_WITH_FAMILY_AND_OR_FRIENDS = 'spendingTimeWithFamilyAndOrFriends'
export const SHARING_A_STORY_PICTURE_OR_VIDEO_ON_SOCIAL_MEDIA = 'sharingAStoryPictureOrVideoOnSocialMedia'
export const BEING_IN_NATURE = 'beingInNature'
export const SHARING_YOUR_OUTDOOR_SKILLS_AND_KNOWLEDGE_WITH_OTHERS = 'sharingYourOutdoorSkillsAndKnowledgeWithOthers'
export const EXPERIENCING_THE_THRILL_EXHAILERATION_AND_RUSH = 'experiencingTheThrillExhailerationAndRush'
export const FEELING_CAREFREE_AND_OR_RELAXED = 'feelingCarefreeAndOrRelaxed'
export const BEING_AWAY_FROM_CROWDS = 'beingAwayFromCrowds'
export const EXERCISING_AND_WORKING_ON_YOUR_PHYSICAL_FITNESS = 'exercisingAndWorkingOnYourPhysicalFitness'
export const PRACTICING_IMPROVING_YOUR_RISK_MGMT_SKILLS = 'practicingImprovingYourRiskManagementSkills'
export const EXPERIENCING_RISK = 'experiencingRisk'
export const ENJOYING_POWDER_SNOW_CONDITIONS_PERMITTING = 'enjoyingPowderSnowConditionsPermitting'
export const CHALLENGING_IMPROVING_YOUR_TECHNICAL_SKILLS = 'challengingImprovingYourTechnicalSkills'
export const REACHING_A_SUMMIT_OR_SIMILAR_TRIP_OBJECTIVE = 'reachingASummitOrSimilarTripObjective'
export const COMPLETING_CLASSIC_WELL_KNOWN_RESPECTED_TRIPS = 'completingClassicWellKnownRespectedTrips'
export const EXPLORING_NEW_OR_DIFFERENT_PLACES = 'exploringNewOrDifferentPlaces'
export const EXPERIENCING_REMOTENESS = 'experiencingRemoteness'
export const DECISION_MAKING_CONTRIBUTION = 'decisionMakingContribution'
export const INFORMATION_SOURCES_CONSULTED = 'informationSourcesConsulted'
export const INFORMATION_SOURCES_CONSULTED_OTHER = 'informationSourcesConsultedOther'
export const AVALANCHE_FORECAST_CHECKED_FREQUENCY = 'avalancheForecastCheckedFrequency'
export const AVALANCHE_FORECAST_USAGE = 'avalancheForecastUsage'
export const SAFETY_EQUIPMENT_TYPICALLY_USED = 'safetyEquipmentTypicallyUsed'
export const TRACK_WITH_GPS = 'trackWithGps'
export const GPS_TRACKING_PLATFORMS = 'gpsTrackingPlatforms'
export const GPS_TRACKING_PLATFORM_OTHER = 'gpsTrackingPlatformOther'

export const MOTIVATION_KEYS = [
    NAVIGATING_THROUGH_CHALLENGING_TERRAIN,
    SPENDING_TIME_WITH_FAMILY_AND_OR_FRIENDS,
    SHARING_A_STORY_PICTURE_OR_VIDEO_ON_SOCIAL_MEDIA,
    BEING_IN_NATURE,
    SHARING_YOUR_OUTDOOR_SKILLS_AND_KNOWLEDGE_WITH_OTHERS,
    EXPERIENCING_THE_THRILL_EXHAILERATION_AND_RUSH,
    FEELING_CAREFREE_AND_OR_RELAXED,
    BEING_AWAY_FROM_CROWDS,
    EXERCISING_AND_WORKING_ON_YOUR_PHYSICAL_FITNESS,
    PRACTICING_IMPROVING_YOUR_RISK_MGMT_SKILLS,
    EXPERIENCING_RISK,
    ENJOYING_POWDER_SNOW_CONDITIONS_PERMITTING,
    CHALLENGING_IMPROVING_YOUR_TECHNICAL_SKILLS,
    REACHING_A_SUMMIT_OR_SIMILAR_TRIP_OBJECTIVE,
    COMPLETING_CLASSIC_WELL_KNOWN_RESPECTED_TRIPS,
    EXPLORING_NEW_OR_DIFFERENT_PLACES,
    EXPERIENCING_REMOTENESS,
] as const

export const GENERALLY_UP_TO_OTHERS = 'generallyUpToOthers'
export const SPEAK_UP_WHEN_CONCERNED = 'speakUpWhenConcerned'
export const EVERYBODY_CONTRIBUTES = 'everybodyContributes'
export const SMALL_NUMBER_OF_INDIVIDUALS_FOR_LARGER_GROUP = 'smallNumberOfIndividualsForLargerGroup'
export const PRIMARY_DECISION_MAKER = 'primaryDecisionMaker'

export const DECISION_MAKING_CONTRIBUTION_OPTIONS = [
    GENERALLY_UP_TO_OTHERS,
    SPEAK_UP_WHEN_CONCERNED,
    EVERYBODY_CONTRIBUTES,
    SMALL_NUMBER_OF_INDIVIDUALS_FOR_LARGER_GROUP,
    PRIMARY_DECISION_MAKER,
] as const

export const REGIONAL_AVALANCHE_FORECAST = 'regionalAvalancheForecast'
export const MOUNTAIN_INFORMATION_NETWORK_REPORTS = 'mountainInformationNetworkReports'
export const MOUNTAIN_WEATHER_FORECAST_AVALANCHE_CANADA = 'mountainWeatherForecastAvalancheCanada'
export const LOCAL_WEATHER_FORECAST = 'localWeatherForecast'
export const LOCAL_WEATHER_STATIONS = 'localWeatherStations'
export const TRIP_DESCRIPTIONS = 'tripDescriptions'
export const TOPOGRAPHIC_MAPS = 'topographicMaps'
export const SOCIAL_MEDIA = 'socialMedia'
export const CONVERSATIONS_WITH_KNOWLEDGEABLE_PEOPLE_YOU_TRUST = 'conversationsWithKnowledgeablePeopleYouTrust'

export const INFORMATION_SOURCES_CONSULTED_OPTIONS = [
    REGIONAL_AVALANCHE_FORECAST,
    MOUNTAIN_INFORMATION_NETWORK_REPORTS,
    MOUNTAIN_WEATHER_FORECAST_AVALANCHE_CANADA,
    LOCAL_WEATHER_FORECAST,
    LOCAL_WEATHER_STATIONS,
    TRIP_DESCRIPTIONS,
    TOPOGRAPHIC_MAPS,
    SOCIAL_MEDIA,
    CONVERSATIONS_WITH_KNOWLEDGEABLE_PEOPLE_YOU_TRUST,
    INFORMATION_SOURCES_CONSULTED_OTHER,
] as const

export const AVALANCHE_TRANSCEIVER = 'avalancheTransceiver'
export const AVALANCHE_PROBE = 'avalancheProbe'
export const AVALANCHE_SHOVEL = 'avalancheShovel'
export const FIRST_AID_KIT = 'firstAidKit'
export const MOBILE_PHONE = 'mobilePhone'
export const EMERGENCY_COMMUNICATION_DEVICE = 'emergencyCommunicationDevice'
export const AVALANCHE_AIRBAG = 'avalancheAirbag'
export const HELMET = 'helmet'

export const SAFETY_EQUIPMENT_TYPICALLY_USED_OPTIONS = [
    AVALANCHE_TRANSCEIVER,
    AVALANCHE_PROBE,
    AVALANCHE_SHOVEL,
    FIRST_AID_KIT,
    MOBILE_PHONE,
    EMERGENCY_COMMUNICATION_DEVICE,
    AVALANCHE_AIRBAG,
    HELMET,
] as const

export const RARELY = 'rarely'
export const BEFORE_MOST_BACKCOUNTRY_TRIPS = 'beforeMostBackcountryTrips'
export const BEFORE_EVERY_BACKCOUNTRY_TRIP = 'beforeEveryBackcountryTrip'
export const BEFORE_EVERY_BACKCOUNTRY_TRIP_AND_OCCASIONALLY_IN_BETWEEN =
    'beforeEveryBackcountryTripAndOccasionallyInBetween'
export const EVERY_DAY_DURING_THE_WINTER = 'everyDayDuringTheWinter'

export const AVALANCHE_FORECAST_CHECKED_FREQUENCY_OPTIONS = [
    RARELY,
    BEFORE_MOST_BACKCOUNTRY_TRIPS,
    BEFORE_EVERY_BACKCOUNTRY_TRIP,
    BEFORE_EVERY_BACKCOUNTRY_TRIP_AND_OCCASIONALLY_IN_BETWEEN,
    EVERY_DAY_DURING_THE_WINTER,
] as const

export const CHECK_DANGER_RATING = 'checkDangerRating'
export const COMBINE_DANGER_RATING_AND_AREA_KNOWLEDGE = 'combineDangerRatingAndAreaKnowledge'
export const DECISION_BASED_ON_PROBLEMS = 'decisionBasedOnProblems'
export const CONTINUOUS_ASSESSMENT = 'continuousAssessment'

export const AVALANCHE_FORECAST_USAGE_OPTIONS = [
    CHECK_DANGER_RATING,
    COMBINE_DANGER_RATING_AND_AREA_KNOWLEDGE,
    DECISION_BASED_ON_PROBLEMS,
    CONTINUOUS_ASSESSMENT,
] as const

export const STRAVA = 'strava'
export const GARMIN_CONNECT = 'garminConnect'
export const ALLTRAILS = 'alltrails'
export const KOMOOT = 'komoot'
export const OUTDOORACTIVE = 'outdooractive'
export const TRAILFORKS = 'trailforks'
export const RELIVE = 'relive'
export const IHIKEGPS = 'ihikegps'
export const GAIA_GPS = 'gaiaGps'
export const CALTOPO = 'calTopo'
export const TOPO_MAPS_CANADA = 'topoMapsCanada'

export const GPS_PLATFORM_OPTIONS = [
    STRAVA,
    GARMIN_CONNECT,
    ALLTRAILS,
    KOMOOT,
    OUTDOORACTIVE,
    TRAILFORKS,
    RELIVE,
    IHIKEGPS,
    GAIA_GPS,
    CALTOPO,
    TOPO_MAPS_CANADA,
    GPS_TRACKING_PLATFORM_OTHER,
] as const

export const motivationMessages = defineMessages({
    generallyUpToOthers: {
        id: 'UserResearchPanel.Motivations.generallyUpToOthers',
        defaultMessage:
            'I leave the decision-making completely to others (e.g., more experienced group member, trip leader).',
        description: 'User Research Panel',
    },
    speakUpWhenConcerned: {
        id: 'UserResearchPanel.Motivations.speakUpWhenConcerned',
        defaultMessage: 'I speak up when I have concerns, but I generally leave the decision-making to others.',
        description: 'User Research Panel',
    },
    everybodyContributes: {
        id: 'UserResearchPanel.Motivations.everybodyContributes',
        defaultMessage: 'Everybody in our group contributes to the decisions equally.',
        description: 'User Research Panel',
    },
    smallNumberOfIndividualsForLargerGroup: {
        id: 'UserResearchPanel.Motivations.smallNumberOfIndividualsForLargerGroup',
        defaultMessage:
            'I am part of a small number of individuals who make the decisions for the larger group together.',
        description: 'User Research Panel',
    },
    primaryDecisionMaker: {
        id: 'UserResearchPanel.Motivations.primaryDecisionMaker',
        defaultMessage: 'I am the primary/sole decision-maker in the group (formal or informal).',
        description: 'User Research Panel',
    },
    regionalAvalancheForecast: {
        id: 'UserResearchPanel.Motivations.regionalAvalancheForecast',
        defaultMessage: 'Regional avalanche forecast',
        description: 'User Research Panel',
    },
    mountainInformationNetworkReports: {
        id: 'UserResearchPanel.Motivations.mountainInformationNetworkReports',
        defaultMessage: 'Mountain Information Network reports',
        description: 'User Research Panel',
    },
    mountainWeatherForecastAvalancheCanada: {
        id: 'UserResearchPanel.Motivations.mountainWeatherForecastAvalancheCanada',
        defaultMessage: 'Mountain Weather Forecast (Avalanche Canada)',
        description: 'User Research Panel',
    },
    localWeatherForecast: {
        id: 'UserResearchPanel.Motivations.localWeatherForecast',
        defaultMessage: 'Local weather forecast',
        description: 'User Research Panel',
    },
    localWeatherStations: {
        id: 'UserResearchPanel.Motivations.localWeatherStations',
        defaultMessage: 'Local weather stations',
        description: 'User Research Panel',
    },
    tripDescriptions: {
        id: 'UserResearchPanel.Motivations.tripDescriptions',
        defaultMessage: 'Trip descriptions (e.g., guidebook, online)',
        description: 'User Research Panel',
    },
    topographicMaps: {
        id: 'UserResearchPanel.Motivations.topographicMaps',
        defaultMessage: 'Topographic maps (paper or digital)',
        description: 'User Research Panel',
    },
    socialMedia: {
        id: 'UserResearchPanel.Motivations.socialMedia',
        defaultMessage: 'Social media',
        description: 'User Research Panel',
    },
    conversationsWithKnowledgeablePeopleYouTrust: {
        id: 'UserResearchPanel.Motivations.conversationsWithKnowledgeablePeopleYouTrust',
        defaultMessage: 'Conversations with knowledgeable people you trust',
        description: 'User Research Panel',
    },
    informationSourcesConsultedOther: {
        id: 'UserResearchPanel.Motivations.informationSourcesConsultedOther',
        defaultMessage: 'Other',
        description: 'User Research Panel',
    },
    avalancheTransceiver: {
        id: 'UserResearchPanel.Motivations.avalancheTransceiver',
        defaultMessage: 'Avalanche transceiver',
        description: 'User Research Panel',
    },
    avalancheProbe: {
        id: 'UserResearchPanel.Motivations.avalancheProbe',
        defaultMessage: 'Avalanche probe',
        description: 'User Research Panel',
    },
    avalancheShovel: {
        id: 'UserResearchPanel.Motivations.avalancheShovel',
        defaultMessage: 'Avalanche shovel',
        description: 'User Research Panel',
    },
    firstAidKit: {
        id: 'UserResearchPanel.Motivations.firstAidKit',
        defaultMessage: 'First aid kit',
        description: 'User Research Panel',
    },
    mobilePhone: {
        id: 'UserResearchPanel.Motivations.mobilePhone',
        defaultMessage: 'Mobile phone',
        description: 'User Research Panel',
    },
    emergencyCommunicationDevice: {
        id: 'UserResearchPanel.Motivations.emergencyCommunicationDevice',
        defaultMessage: 'Emergency communication device (e.g., radio, satellite messenger)',
        description: 'User Research Panel',
    },
    avalancheAirbag: {
        id: 'UserResearchPanel.Motivations.avalancheAirbag',
        defaultMessage: 'Avalanche airbag',
        description: 'User Research Panel',
    },
    helmet: {
        id: 'UserResearchPanel.Motivations.helmet',
        defaultMessage: 'Helmet',
        description: 'User Research Panel',
    },
    rarely: {
        id: 'UserResearchPanel.Motivations.rarely',
        defaultMessage: 'Rarely',
        description: 'User Research Panel',
    },
    beforeMostBackcountryTrips: {
        id: 'UserResearchPanel.Motivations.beforeMostBackcountryTrips',
        defaultMessage: 'Before most backcountry trips',
        description: 'User Research Panel',
    },
    beforeEveryBackcountryTrip: {
        id: 'UserResearchPanel.Motivations.beforeEveryBackcountryTrip',
        defaultMessage: 'Before every backcountry trip',
        description: 'User Research Panel',
    },
    beforeEveryBackcountryTripAndOccasionallyInBetween: {
        id: 'UserResearchPanel.Motivations.beforeEveryBackcountryTripAndOccasionallyInBetween',
        defaultMessage: 'Before every backcountry trip and occasionally in between',
        description: 'User Research Panel',
    },
    everyDayDuringTheWinter: {
        id: 'UserResearchPanel.Motivations.everyDayDuringTheWinter',
        defaultMessage: 'Every day during the winter',
        description: 'User Research Panel',
    },
    checkDangerRating: {
        id: 'UserResearchPanel.Motivations.checkDangerRating',
        defaultMessage:
            'I typically use the forecast to check the danger rating which informs my decision of whether or not it’s safe to travel in the backcountry.',
        description: 'User Research Panel',
    },
    combineDangerRatingAndAreaKnowledge: {
        id: 'UserResearchPanel.Motivations.combineDangerRatingAndAreaKnowledge',
        defaultMessage:
            'I typically combine the danger rating from the forecast with knowledge of how avalanche prone an area is to determine where to travel in the backcountry.',
        description: 'User Research Panel',
    },
    decisionBasedOnProblems: {
        id: 'UserResearchPanel.Motivations.decisionBasedOnProblems',
        defaultMessage:
            'I typically make a decision about where or when to go based on the nature of the avalanche problem conditions reported in the forecast and whether I feel that I can manage my travel in the terrain given these conditions.',
        description: 'User Research Panel',
    },
    continuousAssessment: {
        id: 'UserResearchPanel.Motivations.continuousAssessment',
        defaultMessage:
            'I typically use the available information about the specific nature of the avalanche problem conditions from the forecast as a starting point for my continuous assessment in the field to confirm or disconfirm the information where I am travelling.',
        description: 'User Research Panel',
    },
    strava: {
        id: 'UserResearchPanel.Motivations.strava',
        defaultMessage: 'Strava',
        description: 'User Research Panel',
    },
    garminConnect: {
        id: 'UserResearchPanel.Motivations.garminConnect',
        defaultMessage: 'Garmin Connect',
        description: 'User Research Panel',
    },
    alltrails: {
        id: 'UserResearchPanel.Motivations.alltrails',
        defaultMessage: 'Alltrails',
        description: 'User Research Panel',
    },
    komoot: {
        id: 'UserResearchPanel.Motivations.komoot',
        defaultMessage: 'Komoot',
        description: 'User Research Panel',
    },
    outdooractive: {
        id: 'UserResearchPanel.Motivations.outdooractive',
        defaultMessage: 'Outdooractive',
        description: 'User Research Panel',
    },
    trailforks: {
        id: 'UserResearchPanel.Motivations.trailforks',
        defaultMessage: 'Trailforks',
        description: 'User Research Panel',
    },
    relive: {
        id: 'UserResearchPanel.Motivations.relive',
        defaultMessage: 'Relive',
        description: 'User Research Panel',
    },
    ihikegps: {
        id: 'UserResearchPanel.Motivations.ihikegps',
        defaultMessage: 'iHikeGPS',
        description: 'User Research Panel',
    },
    gaiaGps: {
        id: 'UserResearchPanel.Motivations.gaiaGps',
        defaultMessage: 'Gaia GPS',
        description: 'User Research Panel',
    },
    calTopo: {
        id: 'UserResearchPanel.Motivations.calTopo',
        defaultMessage: 'Caltopo',
        description: 'User Research Panel',
    },
    topoMapsCanada: {
        id: 'UserResearchPanel.Motivations.topoMapsCanada',
        defaultMessage: 'Topo Maps Canada',
        description: 'User Research Panel',
    },
    yes: {
        id: 'UserResearchPanel.Motivations.yes',
        defaultMessage: 'Yes',
        description: 'User Research Panel',
    },
    no: {
        id: 'UserResearchPanel.Motivations.no',
        defaultMessage: 'No',
        description: 'User Research Panel',
    },
    gpsTrackingPlatformOther: {
        id: 'UserResearchPanel.Motivations.gpsTrackingPlatformOther',
        defaultMessage: 'Other GPS tracking platform',
        description: 'User Research Panel',
    },
    navigatingThroughChallengingTerrain: {
        id: 'UserResearchPanel.Motivations.navigatingThroughChallengingTerrain',
        defaultMessage: 'Navigating through challenging terrain (conditions permitting)',
        description: 'User Research Panel',
    },
    spendingTimeWithFamilyAndOrFriends: {
        id: 'UserResearchPanel.Motivations.spendingTimeWithFamilyAndOrFriends',
        defaultMessage: 'Spending time with family and/or friends',
        description: 'User Research Panel',
    },
    sharingAStoryPictureOrVideoOnSocialMedia: {
        id: 'UserResearchPanel.Motivations.sharingAStoryPictureOrVideoOnSocialMedia',
        defaultMessage: 'Sharing a story, picture, or video on social media',
        description: 'User Research Panel',
    },
    beingInNature: {
        id: 'UserResearchPanel.Motivations.beingInNature',
        defaultMessage: 'Being in nature',
        description: 'User Research Panel',
    },
    sharingYourOutdoorSkillsAndKnowledgeWithOthers: {
        id: 'UserResearchPanel.Motivations.sharingYourOutdoorSkillsAndKnowledgeWithOthers',
        defaultMessage: 'Sharing your outdoor skills and knowledge with others',
        description: 'User Research Panel',
    },
    experiencingTheThrillExhailerationAndRush: {
        id: 'UserResearchPanel.Motivations.experiencingTheThrillExhailerationAndRush',
        defaultMessage: 'Experiencing the thrill, exhilaration, and rush',
        description: 'User Research Panel',
    },
    feelingCarefreeAndOrRelaxed: {
        id: 'UserResearchPanel.Motivations.feelingCarefreeAndOrRelaxed',
        defaultMessage: 'Feeling carefree and/or relaxed',
        description: 'User Research Panel',
    },
    beingAwayFromCrowds: {
        id: 'UserResearchPanel.Motivations.beingAwayFromCrowds',
        defaultMessage: 'Being away from crowds',
        description: 'User Research Panel',
    },
    exercisingAndWorkingOnYourPhysicalFitness: {
        id: 'UserResearchPanel.Motivations.exercisingAndWorkingOnYourPhysicalFitness',
        defaultMessage: 'Exercising and working on your physical fitness',
        description: 'User Research Panel',
    },
    practicingImprovingYourRiskManagementSkills: {
        id: 'UserResearchPanel.Motivations.practicingImprovingYourRiskManagementSkills',
        defaultMessage: 'Practicing/improving your risk management skills',
        description: 'User Research Panel',
    },
    experiencingRisk: {
        id: 'UserResearchPanel.Motivations.experiencingRisk',
        defaultMessage: 'Experiencing risk',
        description: 'User Research Panel',
    },
    enjoyingPowderSnowConditionsPermitting: {
        id: 'UserResearchPanel.Motivations.enjoyingPowderSnowConditionsPermitting',
        defaultMessage: 'Enjoying powder snow (conditions permitting)',
        description: 'User Research Panel',
    },
    challengingImprovingYourTechnicalSkills: {
        id: 'UserResearchPanel.Motivations.challengingImprovingYourTechnicalSkills',
        defaultMessage: 'Challenging/improving your technical skills',
        description: 'User Research Panel',
    },
    reachingASummitOrSimilarTripObjective: {
        id: 'UserResearchPanel.Motivations.reachingASummitOrSimilarTripObjective',
        defaultMessage: 'Reaching a summit or similar trip objective',
        description: 'User Research Panel',
    },
    completingClassicWellKnownRespectedTrips: {
        id: 'UserResearchPanel.Motivations.completingClassicWellKnownRespectedTrips',
        defaultMessage: 'Completing classic/well-known/respected trips',
        description: 'User Research Panel',
    },
    exploringNewOrDifferentPlaces: {
        id: 'UserResearchPanel.Motivations.exploringNewOrDifferentPlaces',
        defaultMessage: 'Exploring new or different places',
        description: 'User Research Panel',
    },
    experiencingRemoteness: {
        id: 'UserResearchPanel.Motivations.experiencingRemoteness',
        defaultMessage: 'Experiencing remoteness',
        description: 'User Research Panel',
    },
})
