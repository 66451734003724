import { createContext, useMemo, useContext as useReactContext } from 'react'

import { isAfter, isWithinRange } from 'date-fns'
import { useRouter } from 'next/router'

import * as URLUtils from '@avcan/utils/url'

import Forecasts from 'clients/forecasts'
import PrismicData from 'prismic/PrismicData.preval'
import { EN } from 'constants/general'

const documents = PrismicData.forecastDocuments

export function Provider({ product, children }) {
    const router = useRouter()

    const value = useMemo(() => createValue(product, documents, router), [product, documents])

    return <context.Provider value={value}>{children}</context.Provider>
}

export async function get(id, locale) {
    const promises = [Forecasts.product(id, locale)]
    const [product] = await Promise.all(promises)

    return { product }
}

// Hook: aka getters
export function useForecast() {
    return useContextSlice('product')
}
export function useDocuments() {
    return useContextSlice('documents')
}
export function useDocument(uid) {
    return useDocuments().get(uid)
}
export function useReport() {
    const forecast = useForecast()

    return forecast?.report
}
export function useMedia() {
    const forecast = useForecast()

    return forecast?.media
}
export function useContext() {
    return useReactContext(context)
}
export function usePrintable() {
    return useContextSlice('printable')
}
// TODO: remove this hook. Printing mode removed the tabs,
// but we don't use tabs anymore so regular layout is fine for printing.
export function usePrinting() {
    return useContextSlice('printing')
}
export function usePrintURL() {
    return useContextSlice('printURL')
}
function useContextSlice(name) {
    const ctx = useContext()
    return ctx ? ctx[name] : null
}

const context = createContext()

// createValue - creates the context value which includes the forecast (product), forecast documents (such as disclaimer, rss message, etc),
// and a few values that are nice to have accessible
const createValue = (product, documents, router) => {
    const { dateIssued, validUntil } = product.report
    const printable = validUntil ? isWithinRange(new Date(), dateIssued, validUntil) : isAfter(new Date(), dateIssued)

    return {
        product,
        documents: new Map(documents[router.locale || EN].map(document => [document.uid, document])),
        printable,
        printURL: URLUtils.buildPath(router.asPath, 'print'),
        printing: printable && router.route.includes('/print'),
    }
}
