import { defineMessages } from 'react-intl'

export const messages = defineMessages({
    selectAtLeastOne: {
        id: 'selectAtLeastOne',
        defaultMessage: 'Please select at least one option',
        description: 'Validation schema',
    },
    radioRequired: {
        id: 'radio.required',
        defaultMessage: 'Please select an option',
        description: 'Validation schema',
    },
    informedConsentRequired: {
        id: 'informedConsent.required',
        defaultMessage: 'You must agree to participate in the research panel',
        description: 'Validation schema',
    },
    dataPrivacyRequired: {
        id: 'dataPrivacy.required',
        defaultMessage: 'You must select a value for this field',
        description: 'Validation schema',
    },
    emailRequired: {
        id: 'email.required',
        defaultMessage: 'Please enter your email address',
        description: 'Validation schema',
    },
    genderRequired: {
        id: 'gender.required',
        defaultMessage: 'Please select your gender',
        description: 'Validation schema',
    },
    yearOfBirthRequired: {
        id: 'yearOfBirth.required',
        defaultMessage: 'Please enter your year of birth',
        description: 'Validation schema',
    },
    yearOfBirthMin: {
        id: 'yearOfBirth.min',
        defaultMessage: 'Year must be 1900 or later',
        description: 'Validation schema',
    },
    yearOfBirthMinValue: {
        id: 'yearOfBirth.minValue',
        defaultMessage: 'You must be at least 18 years old to participate in this study',
        description: 'Validation schema',
    },
    yearOfBirthMax: {
        id: 'yearOfBirth.max',
        defaultMessage: 'Year cannot be in the future',
        description: 'Validation schema',
    },
    countryRequired: {
        id: 'country.required',
        defaultMessage: 'Please select your country',
        description: 'Validation schema',
    },
    cityRequired: {
        id: 'city.required',
        defaultMessage: 'Please enter your city',
        description: 'Validation schema',
    },
    postalCodeRequired: {
        id: 'postalCode.required',
        defaultMessage: 'Please enter your postal code',
        description: 'Validation schema',
    },
    postalCodeMin: {
        id: 'postalCode.min',
        defaultMessage: 'Postal code must be at least 3 characters',
        description: 'Validation schema',
    },
    emailInvalid: {
        id: 'email.invalid',
        defaultMessage: 'Please enter a valid email address',
        description: 'Validation schema',
    },
    mostCommonBackcountryActivityRequired: {
        id: 'mostCommonBackcountryActivity.required',
        defaultMessage: 'Please select your most common backcountry activity',
        description: 'Validation schema',
    },
    numberWintersRequired: {
        id: 'numberWinters.required',
        defaultMessage: 'Please select your number of winters',
        description: 'Validation schema',
    },
    daysPerWinterRequired: {
        id: 'daysPerWinter.required',
        defaultMessage: 'Please select your days per winter',
        description: 'Validation schema',
    },
    regionRequired: {
        id: 'region.required',
        defaultMessage: 'Please select at least one region',
        description: 'Validation schema',
    },
    timePeriodRequired: {
        id: 'timePeriod.required',
        defaultMessage: 'Please select at least one time period',
        description: 'Validation schema',
    },
    weekDaysInBackcountryRequired: {
        id: 'weekDaysInBackcountry.required',
        defaultMessage: 'Please select at least one day',
        description: 'Validation schema',
    },
    personalInvolvementRequired: {
        id: 'personalInvolvement.required',
        defaultMessage: 'Please select at least one form of personal involvement',
        description: 'Validation schema',
    },
    yearBecameProfessionalMin: {
        id: 'yearBecameProfessional.min',
        defaultMessage: 'Year must be 1900 or later',
        description: 'Validation schema',
    },
    yearBecameProfessionalMax: {
        id: 'yearBecameProfessional.max',
        defaultMessage: 'Year cannot be in the future',
        description: 'Validation schema',
    },
})
