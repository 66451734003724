import Client, { Predicates } from '../'
import * as PredicateUtils from './predicates'
import * as ObjectUtils from '@avcan/utils/object'

// News, Blog & Event getters
export function get(type, uid, locale, options) {
    const predicate = PredicateUtils.uid(type, uid)

    return Client.queryFirst(predicate, Object.assign({ locale }, options))
}

export async function getRelated(type, uid, orderings, locale, morePredicates = []) {
    const predicates = [
        PredicateUtils.type(type),
        Predicates.not(PredicateUtils.my(type, 'uid'), uid),
        ...morePredicates,
    ]
    const options = { pageSize: 7, orderings, locale }
    const payload = await Client.query(predicates, options)

    return payload.results
}

export async function getList(predicates, options = {}) {
    options = { page: 1, pageSize: 10, ...options }

    const { page, total_pages, results } = await Client.query(predicates, options)
    const payload = { page: page || null, totalPages: total_pages || null, posts: results }

    if (page === 1) {
        payload.posts = moveFeaturedPostFirst(payload.posts)
    }

    return payload
}

// Used in Splash
export async function getForTags({ type, tags, orderings, predicates = [], pageSize = 5, locale }) {
    predicates.push(PredicateUtils.type(type))

    if ((Array.isArray(tags) && tags.length > 0) || (typeof tags === 'string' && tags.length > 0)) {
        predicates.push(PredicateUtils.tags(tags))
    }

    const options = ObjectUtils.clean({ pageSize, orderings, locale })
    const { results } = await Client.query(predicates, options)

    return results
}

// Internals
function isFeaturedPost({ tags }) {
    return tags.includes('featured')
}
function moveFeaturedPostFirst(posts) {
    if (!posts.some(isFeaturedPost)) {
        return posts
    }

    const featured = posts.find(isFeaturedPost)

    posts = posts.filter(post => featured !== post)
    posts.unshift(featured)

    return posts
}
