import { ReactNode, useEffect, useRef } from 'react'

import PhotoSwipeLightbox from 'photoswipe/lightbox'
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin'
import { CldImage } from 'next-cloudinary'

import 'photoswipe/style.css'
import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css'

interface LightboxProps {
    src: string
    alt: string
    width: number
    height: number
    caption?: string | ReactNode
}

export const Lightbox = ({ src, alt, width, height, caption }: LightboxProps) => {
    const lightboxRef = useRef<PhotoSwipeLightbox | null>(null)

    useEffect(() => {
        lightboxRef.current = new PhotoSwipeLightbox({
            gallery: '#gallery',
            children: 'a',
            pswpModule: () => import('photoswipe'),
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const captionPlugin = new PhotoSwipeDynamicCaption(lightboxRef.current, {
            type: 'below',
        })
        lightboxRef.current.init()

        return () => {
            if (lightboxRef.current) {
                lightboxRef.current.destroy()
                lightboxRef.current = null
            }
        }
    }, [src, width, height, alt, caption])

    return (
        <div id="gallery">
            <a
                href={src}
                data-pswp-width={width}
                data-pswp-height={height}
                target="_blank"
                rel="noreferrer"
                data-pswp-srcset={`${src} ${width}w, ${src.replace('/upload/', '/upload/w_800/')} 800w, ${src.replace(
                    '/upload/',
                    '/upload/w_400/'
                )} 400w`}
                data-pswp-sizes="(min-width: 1200px) 1200px, (min-width: 768px) 800px, 400px"
            >
                <CldImage
                    config={{ cloud: { cloudName: 'avalanche-ca' } }}
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                    sizes={`(max-width: 768px) 100vw, 50vw`}
                    style={styles.image}
                />
                <span className="pswp-caption-content">{caption}</span>
            </a>
        </div>
    )
}

const styles = {
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '4px',
    },
}
