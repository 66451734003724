import { FormattedMessage } from 'react-intl'

import { ImportanceRanking } from 'components/UserResearchPanel/CommonInputs/ImportanceRanking'
import { QuestionGroup } from 'components/UserResearchPanel/CommonInputs/QuestionGroup'
import { motivationMessages } from 'components/UserResearchPanel/SignupSteps/Motivations/MotivationConstants'
import { UserResearchPanelFormValues } from 'components/UserResearchPanel/validationSchema'
import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'

export const RankedMotivation = ({ motivationField }: { motivationField: keyof UserResearchPanelFormValues }) => {
    const { touched, errors } = UserResearchPanelSignupStore()

    return (
        <QuestionGroup
            question={<FormattedMessage {...motivationMessages[motivationField as keyof typeof motivationMessages]} />}
            required
            error={touched[motivationField] && !!errors[motivationField]}
            errorMessage={errors[motivationField as keyof typeof errors]}
        >
            <ImportanceRanking schemaField={motivationField} />
        </QuestionGroup>
    )
}
