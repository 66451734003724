import { FormattedMessage } from 'react-intl'

import { ImportanceRanking } from 'components/UserResearchPanel/CommonInputs/ImportanceRanking'
import { QuestionGroup } from 'components/UserResearchPanel/CommonInputs/QuestionGroup'
import { KNOWLEDGE_SOURCE_OTHER } from 'components/UserResearchPanel/SignupSteps/Experience/ExperienceConstants'
import { KNOWLEDGE_SOURCE_KEYS, knowledgeMessages } from 'components/UserResearchPanel/SignupSteps/Experience/Knowledge'
import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'

type KnowledgeSourceProps = {
    knowledgeSourceField: keyof typeof KNOWLEDGE_SOURCE_KEYS
}

export const KnowledgeSource = ({ knowledgeSourceField }: KnowledgeSourceProps) => {
    const { touched, errors } = UserResearchPanelSignupStore()

    return (
        <QuestionGroup
            question={
                <FormattedMessage
                    {...knowledgeMessages[
                        KNOWLEDGE_SOURCE_KEYS[knowledgeSourceField].messageKey as keyof typeof knowledgeMessages
                    ]}
                />
            }
            required={knowledgeSourceField !== KNOWLEDGE_SOURCE_OTHER}
            error={touched[knowledgeSourceField] && !!errors[knowledgeSourceField]}
            errorMessage={errors[knowledgeSourceField as keyof typeof errors]}
        >
            <ImportanceRanking schemaField={knowledgeSourceField} />
        </QuestionGroup>
    )
}
