import { useEffect } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import useSWR from 'swr'

import { getUserResearchPanelData } from 'clients/user-research-panel'
import { UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'

const USER_RESEARCH_PANEL_DATA_KEY = 'userResearchPanelData'

export const useUserResearchPanelData = () => {
    const { getAccessTokenSilently, user } = useAuth0()

    const { setFormData, setIsInitialSignup, resetForm } = UserResearchPanelSignupStore()

    const { data, error, mutate, ...rest } = useSWR(
        [USER_RESEARCH_PANEL_DATA_KEY, user?.sub],
        async () => {
            const token = await getAccessTokenSilently()
            return getUserResearchPanelData(token)
        },
        {
            revalidateOnFocus: false,
            // On initial signup, the server returns a 500 error, so we don't want to keep retrying (causes a flicker on the page)
            shouldRetryOnError: false,
            revalidateIfStale: false,
        }
    )
    const isLoading = !data && !error

    // Sync the data to the store from the server if available; otherwise, it will come from localStorage
    useEffect(() => {
        if (!isLoading) {
            const isInitialSignup = data?.data === undefined

            if (isInitialSignup) {
                resetForm()
            } else {
                setFormData(data?.data)
                setIsInitialSignup(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]) // Only run once when the data is fetched

    return { data, mutate, error, ...rest, isLoading }
}
