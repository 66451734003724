import { useCallback } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import mixpanel from 'mixpanel-browser'

import { useIsLoaded } from 'stores/MixpanelStore'

export const useSendTrackEvent = () => {
    const { user } = useAuth0()
    const isLoaded = useIsLoaded()

    const sendTrackEvent = useCallback(
        (eventName: string, event: Record<string, unknown>) => {
            if (!isLoaded) return () => {}

            // Have to explicitly handle the forecast object since it comes through as a string and
            // that's the only way to pass it through the dataset in the Summary component.
            // But then it comes through as an object in the forecast opened event.
            let eventObject = event?.forecast
            if (typeof event?.forecast === 'string') {
                eventObject = JSON.parse(event?.forecast)
            }

            process.env.NEXT_PUBLIC_MIXPANEL_TOKEN &&
                mixpanel.track(eventName, {
                    ...event,
                    forecast: eventObject,
                    user_id: user?.sub,
                })
        },
        [isLoaded, user?.sub]
    )

    return sendTrackEvent
}
