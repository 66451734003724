import { defineMessages } from 'react-intl'

export const commonMessages = defineMessages({
    selectAllOptionsThatApply: {
        id: 'UserResearchPanel.CommonInputs.selectAllOptionsThatApply',
        defaultMessage: 'Please select all options that apply.',
        description: 'User Research Panel',
    },
    selectOneOption: {
        id: 'UserResearchPanel.CommonInputs.selectOneOption',
        defaultMessage: 'Please select one of the following options.',
        description: 'User Research Panel',
    },
    progress: {
        id: 'UserResearchPanel.CommonInputs.progress',
        defaultMessage: 'Progress: {progress, plural, =0 {#} =100 {#} other {~#}}%',
        description: 'User Research Panel',
    },
})
