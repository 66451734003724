import ClearIcon from '@mui/icons-material/Clear'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputAdornment,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material'
import { CldImage } from 'next-cloudinary'
import { FormattedMessage, useIntl } from 'react-intl'

import {
    ACTIVITIES,
    ICE_CLIMBING,
    messages,
    SNOWSHOEING_OR_WINTER_HIKING,
} from 'components/UserResearchPanel/SignupSteps/BackcountryActivities/Activities'
import {
    FREQUENCY_OPTIONS,
    frequencyMessages,
} from 'components/UserResearchPanel/SignupSteps/BackcountryActivities/Frequencies'
import {
    BACKCOUNTRY_ACTIVITIES_FIRST,
    BACKCOUNTRY_ACTIVITIES_OTHER,
    BACKCOUNTRY_ACTIVITIES_SECOND,
    BACKCOUNTRY_ACTIVITIES_THIRD,
    BACKCOUNTRY_EXPERIENCE_DAYS,
    BACKCOUNTRY_EXPERIENCE_WINTERS,
    BOS_ACTIVITIES,
    ICE_CLIMBING_EXPOSURE_FREQUENCY,
    REGION,
    SHM_ACTIVITIES,
    SNOWSHOEING_TRAIL_FREQUENCY,
    TERRAIN_FREQUENCIES_BOS_CHALLENGING,
    TERRAIN_FREQUENCIES_BOS_COMPLEX,
    TERRAIN_FREQUENCIES_BOS_EXTREME,
    TERRAIN_FREQUENCIES_BOS_SIMPLE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE,
    TERRAIN_FREQUENCIES_SHM_CHALLENGING,
    TERRAIN_FREQUENCIES_SHM_COMPLEX,
    TERRAIN_FREQUENCIES_SHM_EXTREME,
    TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_SHM_SIMPLE,
    TIME_PERIOD,
    WEEKDAYS_IN_BACKCOUNTRY,
} from 'components/UserResearchPanel/validationSchema'
import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'
import { PERSONAL_DAYS_PER_WINTER, personalDaysPerWinterMessages } from './PersonalDaysPerWinter'
import { EXPERIENCE_YEARS, experienceYearsMessages } from './NumberOfWinters'
import { REGION_OPTIONS, regionMessages } from './Regions'
import { TIME_PERIODS, timePeriodMessages } from './TimePeriods'
import { WEEKDAYS_IN_BACKCOUNTRY_OPTIONS, weekDaysInBackcountryMessages } from './WeekDaysInBackcountry'
import { useATESDescriptionsSHM, useATESDescriptionsIceClimbing, useATESDescriptionsBOS } from './useATESDescriptions'
import { ATESRow } from './ATESRow'
import { commonMessages } from 'components/UserResearchPanel/CommonInputs/CommonMessages'
import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'
import { TextInput } from 'components/UserResearchPanel/CommonInputs/TextInput'
import { Heading } from 'components/UserResearchPanel/CommonInputs/Heading'

const LEVELS = [BACKCOUNTRY_ACTIVITIES_FIRST, BACKCOUNTRY_ACTIVITIES_SECOND, BACKCOUNTRY_ACTIVITIES_THIRD] as const
const SHM_TERRAIN_TYPES = [
    TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_SHM_SIMPLE,
    TERRAIN_FREQUENCIES_SHM_CHALLENGING,
    TERRAIN_FREQUENCIES_SHM_COMPLEX,
    TERRAIN_FREQUENCIES_SHM_EXTREME,
] as const
const ICE_CLIMBING_TERRAIN_TYPES = [
    TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME,
] as const
const BOS_TERRAIN_TYPES = [
    TERRAIN_FREQUENCIES_BOS_SIMPLE,
    TERRAIN_FREQUENCIES_BOS_CHALLENGING,
    TERRAIN_FREQUENCIES_BOS_COMPLEX,
    TERRAIN_FREQUENCIES_BOS_EXTREME,
] as const

export const BackcountryActivities = () => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()
    const selectedRegions = (formData[REGION] as string[]) || []
    const selectedTimePeriods = (formData[TIME_PERIOD] as string[]) || []
    const selectedWeekDaysInBackcountry = (formData[WEEKDAYS_IN_BACKCOUNTRY] as string[]) || []
    const ATESDescriptionsSHM = useATESDescriptionsSHM()
    const ATESDescriptionsIceClimbing = useATESDescriptionsIceClimbing()
    const ATESDescriptionsBOS = useATESDescriptionsBOS()
    const { formatMessage } = useIntl()

    const handleRegionChange = (option: string) => {
        const updatedSelection = selectedRegions.includes(option)
            ? selectedRegions.filter(region => region !== option)
            : [...selectedRegions, option]

        handleChange(REGION, updatedSelection)
    }

    const handleTimePeriodChange = (option: string) => {
        const updatedSelection = selectedTimePeriods.includes(option)
            ? selectedTimePeriods.filter(timePeriod => timePeriod !== option)
            : [...selectedTimePeriods, option]

        handleChange(TIME_PERIOD, updatedSelection)
    }

    const handleWeekDaysInBackcountryChange = (option: string) => {
        const updatedSelection = selectedWeekDaysInBackcountry.includes(option)
            ? selectedWeekDaysInBackcountry.filter(weekDay => weekDay !== option)
            : [...selectedWeekDaysInBackcountry, option]

        handleChange(WEEKDAYS_IN_BACKCOUNTRY, updatedSelection)
    }

    const isOtherActivitySelected = formData[BACKCOUNTRY_ACTIVITIES_FIRST] === 'other'
    const isFirstActivitySHM =
        SHM_ACTIVITIES.includes(formData[BACKCOUNTRY_ACTIVITIES_FIRST] as string) || isOtherActivitySelected

    return (
        <Box>
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Backcountry Activities" />
            </Heading>
            <FormControl component="fieldset" sx={styles.formControl}>
                <QuestionText required>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="What recreational winter backcountry activities do you most often engage in?"
                    />
                </QuestionText>
                <FormHelperText error={touched[BACKCOUNTRY_ACTIVITIES_FIRST] && !!errors[BACKCOUNTRY_ACTIVITIES_FIRST]}>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Please select an activity in at least the first dropdown."
                    />
                </FormHelperText>
                <Box>
                    <QuestionText variant="subquestion">
                        <FormattedMessage defaultMessage="Most common activity" />
                        <TextField
                            select
                            sx={styles.activityInput}
                            value={formData[BACKCOUNTRY_ACTIVITIES_FIRST] || ''}
                            onChange={e => {
                                handleChange(BACKCOUNTRY_ACTIVITIES_FIRST, e.target.value)
                            }}
                            error={touched[BACKCOUNTRY_ACTIVITIES_FIRST] && !!errors[BACKCOUNTRY_ACTIVITIES_FIRST]}
                            InputProps={{
                                'aria-invalid':
                                    touched[BACKCOUNTRY_ACTIVITIES_FIRST] && !!errors[BACKCOUNTRY_ACTIVITIES_FIRST],
                                endAdornment: typeof formData[BACKCOUNTRY_ACTIVITIES_FIRST] === 'string' && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleChange(BACKCOUNTRY_ACTIVITIES_FIRST, '')}
                                            edge="end"
                                            size="small"
                                            sx={styles.clearIcon}
                                            aria-label="Clear"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        >
                            {ACTIVITIES.map(activity => {
                                const otherLevels = LEVELS.filter(l => l !== BACKCOUNTRY_ACTIVITIES_FIRST)
                                const disabled = otherLevels.some(l => formData[l] === activity.name)

                                return (
                                    <MenuItem key={activity.name} value={activity.name} disabled={disabled}>
                                        <FormattedMessage {...messages[activity.name]} />
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                        <Box sx={styles.otherActivityContainer}>
                            {isOtherActivitySelected && (
                                <TextInput
                                    dataKey={BACKCOUNTRY_ACTIVITIES_OTHER}
                                    placeholder={formatMessage({
                                        description: 'User Research Panel',
                                        defaultMessage: 'Other activity',
                                    })}
                                />
                            )}
                        </Box>
                    </QuestionText>
                </Box>

                <Box>
                    <QuestionText variant="subquestion">
                        <FormattedMessage defaultMessage="Second most common activity (if applicable)" />
                        <TextField
                            select
                            sx={styles.activityInput}
                            value={formData[BACKCOUNTRY_ACTIVITIES_SECOND] || ''}
                            onChange={e => {
                                handleChange(BACKCOUNTRY_ACTIVITIES_SECOND, e.target.value)
                            }}
                            error={touched[BACKCOUNTRY_ACTIVITIES_SECOND] && !!errors[BACKCOUNTRY_ACTIVITIES_SECOND]}
                            InputProps={{
                                'aria-invalid':
                                    touched[BACKCOUNTRY_ACTIVITIES_SECOND] && !!errors[BACKCOUNTRY_ACTIVITIES_SECOND],
                                endAdornment: typeof formData[BACKCOUNTRY_ACTIVITIES_SECOND] === 'string' && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleChange(BACKCOUNTRY_ACTIVITIES_SECOND, '')}
                                            edge="end"
                                            size="small"
                                            sx={styles.clearIcon}
                                            aria-label="Clear"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        >
                            {ACTIVITIES.map(activity => {
                                const otherLevels = LEVELS.filter(l => l !== BACKCOUNTRY_ACTIVITIES_SECOND)
                                const disabled = otherLevels.some(l => formData[l] === activity.name)

                                return (
                                    <MenuItem key={activity.name} value={activity.name} disabled={disabled}>
                                        <FormattedMessage {...messages[activity.name]} />
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </QuestionText>
                </Box>
                <Box>
                    <QuestionText variant="subquestion">
                        <FormattedMessage defaultMessage="Third most common activity (if applicable)" />
                        <TextField
                            select
                            sx={styles.activityInput}
                            value={formData[BACKCOUNTRY_ACTIVITIES_THIRD] || ''}
                            onChange={e => {
                                handleChange(BACKCOUNTRY_ACTIVITIES_THIRD, e.target.value)
                            }}
                            error={touched[BACKCOUNTRY_ACTIVITIES_THIRD] && !!errors[BACKCOUNTRY_ACTIVITIES_THIRD]}
                            InputProps={{
                                'aria-invalid':
                                    touched[BACKCOUNTRY_ACTIVITIES_THIRD] && !!errors[BACKCOUNTRY_ACTIVITIES_THIRD],
                                endAdornment: typeof formData[BACKCOUNTRY_ACTIVITIES_THIRD] === 'string' && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => handleChange(BACKCOUNTRY_ACTIVITIES_THIRD, '')}
                                            edge="end"
                                            size="small"
                                            sx={styles.clearIcon}
                                            aria-label="Clear"
                                        >
                                            <ClearIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        >
                            {ACTIVITIES.map(activity => {
                                const otherLevels = LEVELS.filter(l => l !== BACKCOUNTRY_ACTIVITIES_THIRD)
                                const disabled = otherLevels.some(l => formData[l] === activity.name)

                                return (
                                    <MenuItem key={activity.name} value={activity.name} disabled={disabled}>
                                        <FormattedMessage {...messages[activity.name]} />
                                    </MenuItem>
                                )
                            })}
                        </TextField>
                    </QuestionText>
                </Box>
            </FormControl>
            {formData[BACKCOUNTRY_ACTIVITIES_FIRST] === SNOWSHOEING_OR_WINTER_HIKING && (
                <FormControl
                    sx={styles.formControl}
                    error={touched[SNOWSHOEING_TRAIL_FREQUENCY] && !!errors[SNOWSHOEING_TRAIL_FREQUENCY]}
                >
                    <QuestionText required>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="When snowshoeing/winter hiking, how often do you leave marked trails and create your own path?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage {...commonMessages.selectOneOption} />
                    </FormHelperText>
                    <TextField
                        select
                        sx={styles.activityInput}
                        value={formData[SNOWSHOEING_TRAIL_FREQUENCY] || ''}
                        onChange={e => {
                            handleChange(SNOWSHOEING_TRAIL_FREQUENCY, e.target.value)
                        }}
                    >
                        {FREQUENCY_OPTIONS.map(option => (
                            <MenuItem key={option} value={option}>
                                <FormattedMessage {...frequencyMessages[option]} />
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}
            {formData[BACKCOUNTRY_ACTIVITIES_FIRST] === ICE_CLIMBING && (
                <FormControl
                    sx={styles.formControl}
                    error={touched[ICE_CLIMBING_EXPOSURE_FREQUENCY] && !!errors[ICE_CLIMBING_EXPOSURE_FREQUENCY]}
                >
                    <QuestionText required>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="How often would you estimate that your ice climbs are exposed to avalanche hazard from above?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage {...commonMessages.selectOneOption} />
                    </FormHelperText>
                    <TextField
                        select
                        sx={styles.activityInput}
                        value={formData[ICE_CLIMBING_EXPOSURE_FREQUENCY] || ''}
                        onChange={e => {
                            handleChange(ICE_CLIMBING_EXPOSURE_FREQUENCY, e.target.value)
                        }}
                        error={touched[ICE_CLIMBING_EXPOSURE_FREQUENCY] && !!errors[ICE_CLIMBING_EXPOSURE_FREQUENCY]}
                        helperText={touched[SNOWSHOEING_TRAIL_FREQUENCY] && errors[SNOWSHOEING_TRAIL_FREQUENCY]}
                    >
                        {FREQUENCY_OPTIONS.map(option => (
                            <MenuItem key={option} value={option}>
                                <FormattedMessage {...frequencyMessages[option]} />
                            </MenuItem>
                        ))}
                    </TextField>
                </FormControl>
            )}
            <FormControl component="fieldset" sx={styles.formControl}>
                <QuestionText required>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Overall, how much experience do you have in all your recreational winter backcountry activities combined?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Please select the appropriate option in each dropdown."
                    />
                </FormHelperText>
                <QuestionText variant="subquestion">
                    <FormattedMessage defaultMessage="Number of winters" />
                    <TextField
                        select
                        sx={styles.activityInput}
                        value={formData[BACKCOUNTRY_EXPERIENCE_WINTERS] || ''}
                        onChange={e => {
                            handleChange(BACKCOUNTRY_EXPERIENCE_WINTERS, e.target.value)
                        }}
                        error={touched[BACKCOUNTRY_EXPERIENCE_WINTERS] && !!errors[BACKCOUNTRY_EXPERIENCE_WINTERS]}
                        helperText={touched[BACKCOUNTRY_EXPERIENCE_WINTERS] && errors[BACKCOUNTRY_EXPERIENCE_WINTERS]}
                    >
                        {EXPERIENCE_YEARS.map(option => (
                            <MenuItem key={option.name} value={option.name}>
                                <FormattedMessage {...experienceYearsMessages[option.name]} />
                            </MenuItem>
                        ))}
                    </TextField>
                </QuestionText>

                <QuestionText variant="subquestion">
                    <FormattedMessage defaultMessage="Average number of days per winter" />
                    <TextField
                        select
                        sx={styles.activityInput}
                        value={formData[BACKCOUNTRY_EXPERIENCE_DAYS] || ''}
                        onChange={e => {
                            handleChange(BACKCOUNTRY_EXPERIENCE_DAYS, e.target.value)
                        }}
                        error={touched[BACKCOUNTRY_EXPERIENCE_DAYS] && !!errors[BACKCOUNTRY_EXPERIENCE_DAYS]}
                        helperText={touched[BACKCOUNTRY_EXPERIENCE_DAYS] && errors[BACKCOUNTRY_EXPERIENCE_DAYS]}
                    >
                        {PERSONAL_DAYS_PER_WINTER.map(option => (
                            <MenuItem key={option.name} value={option.name}>
                                <FormattedMessage {...personalDaysPerWinterMessages[option.name]} />
                            </MenuItem>
                        ))}
                    </TextField>
                </QuestionText>
            </FormControl>
            <FormControl sx={styles.formControl} error={touched[REGION] && !!errors[REGION]}>
                <QuestionText required>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Which of the following regions do you commonly visit for winter backcountry recreation?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                <Box sx={styles.imageContainer}>
                    <CldImage
                        config={{ cloud: { cloudName: 'avalanche-ca' } }}
                        src={
                            'https://res.cloudinary.com/avalanche-ca/image/upload/v1736449479/User%20Research%20Panel/General_regions.png'
                        }
                        alt={'Regions'}
                        width={940}
                        height={761}
                        sizes="100vw"
                        style={styles.image}
                        crop="fill"
                    />
                </Box>
                {REGION_OPTIONS.map(option => (
                    <FormControlLabel
                        key={option}
                        control={
                            <Checkbox
                                checked={selectedRegions.includes(option)}
                                onChange={() => handleRegionChange(option)}
                                aria-invalid={touched[REGION] && !!errors[REGION]}
                            />
                        }
                        label={<FormattedMessage {...regionMessages[option]} />}
                    />
                ))}
            </FormControl>
            <FormControl sx={styles.formControl} error={touched[TIME_PERIOD] && !!errors[TIME_PERIOD]}>
                <QuestionText required>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="During what part of the winter season do you typically recreate in these regions?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                {TIME_PERIODS.map(option => (
                    <FormControlLabel
                        key={option}
                        control={
                            <Checkbox
                                checked={selectedTimePeriods.includes(option)}
                                onChange={() => handleTimePeriodChange(option)}
                                aria-invalid={touched[TIME_PERIOD] && !!errors[TIME_PERIOD]}
                            />
                        }
                        label={<FormattedMessage {...timePeriodMessages[option]} />}
                    />
                ))}
            </FormControl>
            <FormControl
                sx={styles.formControl}
                error={touched[WEEKDAYS_IN_BACKCOUNTRY] && !!errors[WEEKDAYS_IN_BACKCOUNTRY]}
            >
                <QuestionText required>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="On what days do you typically recreate in these regions?"
                    />
                </QuestionText>
                <FormHelperText>
                    <FormattedMessage {...commonMessages.selectAllOptionsThatApply} />
                </FormHelperText>
                {WEEKDAYS_IN_BACKCOUNTRY_OPTIONS.map(option => (
                    <FormControlLabel
                        key={option}
                        control={
                            <Checkbox
                                checked={selectedWeekDaysInBackcountry.includes(option)}
                                onChange={() => handleWeekDaysInBackcountryChange(option)}
                                aria-invalid={touched[WEEKDAYS_IN_BACKCOUNTRY] && !!errors[WEEKDAYS_IN_BACKCOUNTRY]}
                            />
                        }
                        label={<FormattedMessage {...weekDaysInBackcountryMessages[option]} />}
                    />
                ))}
            </FormControl>
            {isFirstActivitySHM && (
                <FormControl component="fieldset" sx={styles.formControl}>
                    <QuestionText required>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="When recreating in the backcountry and conditions allow for it, how often do you spend time in the following types of terrain?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please select a frequency for each terrain type."
                        />
                    </FormHelperText>
                    {SHM_TERRAIN_TYPES.map(terrainType => (
                        <ATESRow
                            key={terrainType}
                            title={ATESDescriptionsSHM[terrainType].title}
                            description={ATESDescriptionsSHM[terrainType].description}
                            cloudinaryUrl={ATESDescriptionsSHM[terrainType].cloudinaryUrl}
                            width={ATESDescriptionsSHM[terrainType].width}
                            height={ATESDescriptionsSHM[terrainType].height}
                            caption={ATESDescriptionsSHM[terrainType].caption}
                        >
                            <FormControl sx={styles.activityInput}>
                                <RadioGroup
                                    value={formData[terrainType] || ''}
                                    onChange={e => {
                                        handleChange(terrainType, e.target.value)
                                    }}
                                    aria-invalid={touched[terrainType] && !!errors[terrainType]}
                                >
                                    {FREQUENCY_OPTIONS.map(option => (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={<Radio />}
                                            label={<FormattedMessage {...frequencyMessages[option]} />}
                                        />
                                    ))}
                                </RadioGroup>
                                {touched[terrainType] && errors[terrainType] && (
                                    <FormHelperText error>{errors[terrainType]}</FormHelperText>
                                )}
                            </FormControl>
                        </ATESRow>
                    ))}
                </FormControl>
            )}
            {formData[BACKCOUNTRY_ACTIVITIES_FIRST] === ICE_CLIMBING && (
                <FormControl component="fieldset" sx={styles.formControl}>
                    <QuestionText required>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="When recreating in the backcountry and conditions allow for it, how often do you spend time in the following types of terrain?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please select a frequency for each terrain type."
                        />
                    </FormHelperText>
                    {ICE_CLIMBING_TERRAIN_TYPES.map(terrainType => (
                        <ATESRow
                            key={terrainType}
                            title={ATESDescriptionsIceClimbing[terrainType].title}
                            description={ATESDescriptionsIceClimbing[terrainType].description}
                            cloudinaryUrl={ATESDescriptionsIceClimbing[terrainType].cloudinaryUrl}
                            width={ATESDescriptionsIceClimbing[terrainType].width}
                            height={ATESDescriptionsIceClimbing[terrainType].height}
                            caption={ATESDescriptionsIceClimbing[terrainType].caption}
                        >
                            <FormControl sx={styles.activityInput}>
                                <RadioGroup
                                    value={formData[terrainType] || ''}
                                    onChange={e => {
                                        handleChange(terrainType, e.target.value)
                                    }}
                                    aria-invalid={touched[terrainType] && !!errors[terrainType]}
                                >
                                    {FREQUENCY_OPTIONS.map(option => (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={<Radio />}
                                            label={<FormattedMessage {...frequencyMessages[option]} />}
                                        />
                                    ))}
                                </RadioGroup>
                                {touched[terrainType] && errors[terrainType] && (
                                    <FormHelperText error>{errors[terrainType]}</FormHelperText>
                                )}
                            </FormControl>
                        </ATESRow>
                    ))}
                </FormControl>
            )}
            {BOS_ACTIVITIES.includes(formData[BACKCOUNTRY_ACTIVITIES_FIRST] as string) && (
                <FormControl component="fieldset" sx={styles.formControl}>
                    <QuestionText required>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="When recreating in the backcountry and conditions allow for it, how often do you spend time in the following types of terrain?"
                        />
                    </QuestionText>
                    <FormHelperText>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Please select a frequency for each terrain type."
                        />
                    </FormHelperText>
                    {BOS_TERRAIN_TYPES.map(terrainType => (
                        <ATESRow
                            key={terrainType}
                            title={ATESDescriptionsBOS[terrainType].title}
                            description={ATESDescriptionsBOS[terrainType].description}
                            cloudinaryUrl={ATESDescriptionsBOS[terrainType].cloudinaryUrl}
                            width={ATESDescriptionsBOS[terrainType].width}
                            height={ATESDescriptionsBOS[terrainType].height}
                            caption={ATESDescriptionsBOS[terrainType].caption}
                        >
                            <FormControl sx={styles.activityInput}>
                                <RadioGroup
                                    value={formData[terrainType] || ''}
                                    onChange={e => {
                                        handleChange(terrainType, e.target.value)
                                    }}
                                    aria-invalid={touched[terrainType] && !!errors[terrainType]}
                                >
                                    {FREQUENCY_OPTIONS.map(option => (
                                        <FormControlLabel
                                            key={option}
                                            value={option}
                                            control={<Radio />}
                                            label={<FormattedMessage {...frequencyMessages[option]} />}
                                        />
                                    ))}
                                </RadioGroup>
                                {touched[terrainType] && errors[terrainType] && (
                                    <FormHelperText error>{errors[terrainType]}</FormHelperText>
                                )}
                            </FormControl>
                        </ATESRow>
                    ))}
                </FormControl>
            )}
        </Box>
    )
}

const styles = {
    activityInput: {
        margin: '4px 0',
        width: '100%',
    },
    formControl: {
        marginTop: 4,
        width: '100%',
    },
    clearIcon: {
        marginRight: 2,
    },
    flex: {
        display: 'flex' as const,
        gap: '1px',
        flexWrap: 'wrap' as const,
    },
    imageContainer: {
        width: '100%',
        margin: '4px auto',
    },
    image: {
        width: '100%',
        height: 'auto',
    },
    terrainRow: {
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        marginTop: 2,
    },
    terrainLabel: {
        minWidth: 300,
    },
    terrainInput: {
        width: '100%',
    },
    otherActivityContainer: {
        marginTop: 2,
    },
}
