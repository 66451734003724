import { useIntl } from 'react-intl'

import {
    TERRAIN_FREQUENCIES_SHM_COMPLEX,
    TERRAIN_FREQUENCIES_SHM_CHALLENGING,
    TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_SHM_SIMPLE,
    TERRAIN_FREQUENCIES_SHM_EXTREME,
    TERRAIN_FREQUENCIES_BOS_SIMPLE,
    TERRAIN_FREQUENCIES_BOS_CHALLENGING,
    TERRAIN_FREQUENCIES_BOS_COMPLEX,
    TERRAIN_FREQUENCIES_BOS_EXTREME,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX,
    TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME,
} from 'components/UserResearchPanel/validationSchema'

// Snowshoeing, Hiking, and Mountaineering
export const useATESDescriptionsSHM = () => {
    const intl = useIntl()

    const atesData = [
        {
            id: TERRAIN_FREQUENCIES_SHM_NON_AVALANCHE,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Non-Avalanche',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'No known exposure to avalanches. Very low-angle or densely forested slopes located well away from avalanche paths, or designated trails/routes with no exposure to avalanches.',
            }),
            cloudinaryUrl:
                'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890239/ATES/ATES_SNOW_NON_AVALANCHE.jpg',
            width: 1542,
            height: 1326,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of non-avalanche terrain.', // Note: such messages are repeated below, but we need to do this for the static typing required by Format.js
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_SHM_SIMPLE,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Simple terrain (slopes less than 30°)',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Exposure to low-angle or primarily forested terrain. Some forest openings may involve the runout zones of infrequent avalanches and terrain traps may exist. Many options to reduce or eliminate exposure.',
            }),
            cloudinaryUrl: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890238/ATES/ATES_SNOW_SIMPLE.jpg',
            width: 1542,
            height: 1030,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of simple ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_SHM_CHALLENGING,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Challenging terrain (slopes 30-35°)',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Exposure to well-defined avalanche paths, starting zones, terrain traps or overhead hazard. With careful route finding, options exist to reduce or eliminate exposure.',
            }),
            cloudinaryUrl:
                'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890238/ATES/ATES_SNOW_CHALLENGING.jpg',
            width: 1542,
            height: 1030,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of challenging ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_SHM_COMPLEX,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Complex terrain (slopes greater than 35°)',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Exposure to multiple overlapping avalanche paths or large expanses of steep, open terrain. Frequent exposure to overhead hazard. Many avalanche starting zones and terrain traps with minimal options to reduce exposure.',
            }),
            cloudinaryUrl:
                'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890239/ATES/ATES_SNOW_COMPLEX.jpg',
            width: 1510,
            height: 1008,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of complex ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_SHM_EXTREME,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Extreme terrain (slopes greater than 45°)',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Exposure to very steep faces with cliffs, spines, couloirs, crevasses or sustained overhead hazard. No options to reduce exposure; even small avalanches can be fatal.',
            }),
            cloudinaryUrl:
                'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890238/ATES/ATES_SNOW_EXTREME.jpg',
            width: 1542,
            height: 868,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of extreme ATES terrain.',
            }),
        },
    ]

    return atesData.reduce(
        (acc, item) => ({
            ...acc,
            [item.id]: item,
        }),
        {} as Record<string, (typeof atesData)[0]>
    )
}

// Ice Climbing
export const useATESDescriptionsIceClimbing = () => {
    const intl = useIntl()

    // TODO: replace with ice-climbing images once available
    const atesData = [
        {
            id: TERRAIN_FREQUENCIES_ICE_CLIMBING_NON_AVALANCHE,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Non-Avalanche',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Routes with no exposure to avalanches except small sluffs and spindrift.',
            }),
            cloudinaryUrl: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1738283793/ATES/ATES0.png',
            width: 2269,
            height: 3024,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of non-avalanche ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_ICE_CLIMBING_SIMPLE,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Simple',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Routes with brief exposure to very low frequency avalanches starting from above or crossing occasional short slopes.',
            }),
            cloudinaryUrl: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1738352943/ATES/ATES1.png',
            width: 1917,
            height: 1646,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of simple ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_ICE_CLIMBING_CHALLENGING,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Challenging',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Routes with long exposure to low frequency avalanches or brief exposure to high frequency avalanches starting from above or crossing a few short slopes. Options exist to reduce exposure.',
            }),
            cloudinaryUrl: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1738353035/ATES/ATES2.png',
            width: 2739,
            height: 4100,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of challenging ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_ICE_CLIMBING_COMPLEX,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Complex',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Routes with long exposure to high frequency avalanches starting from above or crossing steep slopes with terrain traps below. Minimal options to reduce exposure.',
            }),
            cloudinaryUrl: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1738353067/ATES/ATES3.png',
            width: 915,
            height: 1653,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of complex ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_ICE_CLIMBING_EXTREME,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Extreme',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Routes with long and sustained exposure to very high frequency avalanches starting from above and crossing multiple steep slopes with terrain traps below. No options to reduce exposure.',
            }),
            cloudinaryUrl: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1738352977/ATES/ATES4.png',
            width: 1162,
            height: 1650,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of extreme ATES terrain.',
            }),
        },
    ]

    return atesData.reduce(
        (acc, item) => ({
            ...acc,
            [item.id]: item,
        }),
        {} as Record<string, (typeof atesData)[0]>
    )
}

// Backcountry skiing, Out of bounds skiing, and snowmobiling
export const useATESDescriptionsBOS = () => {
    const intl = useIntl()

    const atesData = [
        {
            id: TERRAIN_FREQUENCIES_BOS_SIMPLE,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Simple terrain (slopes less than 30°)',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Exposure to low-angle or primarily forested terrain. Some forest openings may involve the runout zones of infrequent avalanches and terrain traps may exist. Many options to reduce or eliminate exposure.',
            }),
            cloudinaryUrl: 'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890238/ATES/ATES_SNOW_SIMPLE.jpg',
            width: 1542,
            height: 1030,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of simple ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_BOS_CHALLENGING,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Challenging terrain (slopes 30-35°)',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Exposure to well-defined avalanche paths, starting zones, terrain traps or overhead hazard. With careful route finding, options exist to reduce or eliminate exposure.',
            }),
            cloudinaryUrl:
                'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890238/ATES/ATES_SNOW_CHALLENGING.jpg',
            width: 1542,
            height: 1030,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of challenging ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_BOS_COMPLEX,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Complex terrain (slopes greater than 35°)',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Exposure to multiple overlapping avalanche paths or large expanses of steep, open terrain. Frequent exposure to overhead hazard. Many avalanche starting zones and terrain traps with minimal options to reduce exposure.',
            }),
            cloudinaryUrl:
                'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890239/ATES/ATES_SNOW_COMPLEX.jpg',
            width: 1510,
            height: 1008,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of complex ATES terrain.',
            }),
        },
        {
            id: TERRAIN_FREQUENCIES_BOS_EXTREME,
            title: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'Extreme terrain (slopes greater than 45°)',
            }),
            description: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage:
                    'Exposure to very steep faces with cliffs, spines, couloirs, crevasses or sustained overhead hazard. No options to reduce exposure; even small avalanches can be fatal.',
            }),
            cloudinaryUrl:
                'https://res.cloudinary.com/avalanche-ca/image/upload/v1736890238/ATES/ATES_SNOW_EXTREME.jpg',
            width: 1542,
            height: 868,
            caption: intl.formatMessage({
                description: 'User Research Panel',
                defaultMessage: 'An example of extreme ATES terrain.',
            }),
        },
    ]

    return atesData.reduce(
        (acc, item) => ({
            ...acc,
            [item.id]: item,
        }),
        {} as Record<string, (typeof atesData)[0]>
    )
}
