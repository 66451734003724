import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'

import { Heading } from 'components/UserResearchPanel/CommonInputs/Heading'
import { LINKING_DATA_CONSENT } from 'components/UserResearchPanel/validationSchema'
import { useHandleChange, UserResearchPanelSignupStore } from 'stores/UserResearchPanelSignupStore'

export const DataPrivacy = () => {
    const { formData, touched, errors } = UserResearchPanelSignupStore()
    const handleChange = useHandleChange()

    return (
        <div style={styles.root}>
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Use of Data" />
            </Heading>
            <Typography variant="body1" style={styles.text}>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="We want to understand how you use our products so we can improve them. To do this, we combine insights from surveys with website usage data to see how people interact with our site."
                    />
                </p>
                <ul style={styles.list}>
                    <li style={styles.listItem}>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="We do not track what you do outside of our site."
                        />
                    </li>
                    <li style={styles.listItem}>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="We never sell or share your data beyond our research team."
                        />
                    </li>
                    <li style={styles.listItem}>
                        <FormattedMessage
                            description="User Research Panel"
                            defaultMessage="Your responses and usage data are only used to improve our products and services."
                        />
                    </li>
                </ul>
                <p style={styles.question}>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Would you like to allow us to link your survey responses with your website behavior data?"
                    />
                </p>
                <small style={styles.smallText}>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="You can change your preferences at any time by contacting us or adjusting your account settings."
                    />
                </small>
            </Typography>
            <FormControl component="fieldset" style={styles.formControl}>
                <RadioGroup
                    name="consent"
                    value={String(formData[LINKING_DATA_CONSENT])}
                    onChange={e => handleChange(LINKING_DATA_CONSENT, e.target.value)}
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="Yes, I consent to linking my survey responses with my website usage data"
                            />
                        }
                    />
                    <FormControlLabel
                        value="false"
                        control={
                            <Radio
                                inputProps={{
                                    'aria-invalid': touched[LINKING_DATA_CONSENT] && !!errors[LINKING_DATA_CONSENT],
                                }}
                            />
                        }
                        label={
                            <FormattedMessage
                                description="User Research Panel"
                                defaultMessage="No, I prefer to keep these separate"
                            />
                        }
                    />
                </RadioGroup>
                {touched[LINKING_DATA_CONSENT] && errors[LINKING_DATA_CONSENT] && (
                    <FormHelperText error style={styles.helperText}>
                        {errors[LINKING_DATA_CONSENT]}
                    </FormHelperText>
                )}
            </FormControl>
            <Heading>
                <FormattedMessage description="User Research Panel" defaultMessage="Data Use and Retention" />
            </Heading>
            <Typography variant="body1" style={styles.text}>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="All information you provide here will be confidentially stored on secure servers. Any contact
                information you provide will only be used to contact you to participate in specific studies. The data
                you produce may be presented in academic publications or technical presentations and will be used to
                inform the design of future avalanche safety products. Your identity will always remain anonymous. You
                may opt-out of participation at any time and you may also request to have all of your data destroyed
                permanently. Your data will be stored on secure servers."
                    />
                </p>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="While the data collected throughout this research will be stored indefinitely to facilitate
                    communications and long term studies, you have full control over your data and can change how you want
                    them to be used at any time. To opt-out and delete your data or if you have any questions, please
                    contact us at <a>research@avalanche.ca</a>. We will remind you this
                    is an option in every email contacting you to participate in a new study."
                        values={{
                            a: chunks => <a href="mailto:research@avalanche.ca">{chunks}</a>,
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        description="User Research Panel"
                        defaultMessage="Note that your personal information will never be shared outside of Avalanche Canada and the Avalanche Research
                    Program at Simon Fraser University."
                    />
                </p>
            </Typography>
        </div>
    )
}

const styles = {
    root: {
        marginBottom: '1em',
    },
    text: {
        margin: '0 0 1em',
        lineHeight: 1.6,
        color: '#333',
    },
    smallText: {
        display: 'block',
        fontSize: '0.875em',
        color: '#666',
        marginTop: '0.1em',
        lineHeight: 1.4,
    },
    list: {
        margin: '0 0 1.5em',
        paddingLeft: '1.25em',
    },
    listItem: {
        marginBottom: '0.5em',
        lineHeight: 1.6,
        paddingLeft: '0.5em',
    },
    formControl: {
        width: '100%',
        margin: '1em 0',
    },
    helperText: {
        marginTop: '0.5em',
    },
    question: {
        fontWeight: 600,
        margin: '0 0 0.25em',
    },
}
