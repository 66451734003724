export const USERNAME = 'avalanchecanada'
export const ACCESS_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_ACCESS_TOKEN

export const API = 'https://api.mapbox.com'

export const STYLE_IDS = {
    // TODO: remove the hardcoded styles ids once the build is updated
    default: process.env.NEXT_PUBLIC_MAPBOX_STYLE_ID,
    ates: process.env.NEXT_PUBLIC_MAPBOX_STYLE_ID_ATES,
    grayscale: process.env.NEXT_PUBLIC_MAPBOX_STYLE_ID_GRAYSCALE,
}

const STYLE_ROOT = 'mapbox://styles/' + USERNAME + '/'

export const STYLES = {
    default: STYLE_ROOT + STYLE_IDS.default,
    ates: STYLE_ROOT + STYLE_IDS.ates,
    grayscale: STYLE_ROOT + STYLE_IDS.grayscale,
}

const TILESET_ROOT = 'mapbox://' + USERNAME + '.'
const CLOSURE_ZONES_TILESET_ID = process.env.NEXT_PUBLIC_MAPBOX_CLOSURE_ZONES_TILESET_ID

export const TILESETS = {
    closureZones: TILESET_ROOT + CLOSURE_ZONES_TILESET_ID,
}
