import { defineMessages } from 'react-intl'

export const TIME_PERIODS_LATE_FALL = 'late_fall'
export const TIME_PERIODS_EARLY_WINTER = 'early_winter'
export const TIME_PERIODS_MID_WINTER = 'mid_winter'
export const TIME_PERIODS_LATE_WINTER = 'late_winter'
export const TIME_PERIODS_SPRING = 'spring'

export const TIME_PERIODS = [
    TIME_PERIODS_LATE_FALL,
    TIME_PERIODS_EARLY_WINTER,
    TIME_PERIODS_MID_WINTER,
    TIME_PERIODS_LATE_WINTER,
    TIME_PERIODS_SPRING,
] as const

// Note that these keys are the same as the constants defined above, but React i18n won't allow dynamic key definitions.
export const timePeriodMessages = defineMessages({
    late_fall: {
        id: 'UserResearchPanel.BackcountryActivities.late_fall',
        defaultMessage: 'Late fall / Before avalanche forecasts are published',
    },
    early_winter: {
        id: 'UserResearchPanel.BackcountryActivities.early_winter',
        defaultMessage: 'Early winter months (November, December)',
    },
    mid_winter: {
        id: 'UserResearchPanel.BackcountryActivities.mid_winter',
        defaultMessage: 'Mid winter months (January, February)',
    },
    late_winter: {
        id: 'UserResearchPanel.BackcountryActivities.late_winter',
        defaultMessage: 'Late winter months (March, April)',
    },
    spring: {
        id: 'UserResearchPanel.BackcountryActivities.spring',
        defaultMessage: 'Spring / When avalanche forecasts have stopped being published',
    },
})
