import { defineMessages } from 'react-intl'

export const FIRST_WINTER = 'first_winter'
export const SECOND_WINTER = 'second_winter'
export const THREE_TO_FIVE_WINTERS = 'three_to_five_winters'
export const SIX_TO_TEN_WINTERS = 'six_to_ten_winters'
export const ELEVEN_TO_TWENTY_WINTERS = 'eleven_to_twenty_winters'
export const MORE_THAN_TWENTY_WINTERS = 'more_than_twenty_winters'

export const EXPERIENCE_YEARS = [
    { name: FIRST_WINTER },
    { name: SECOND_WINTER },
    { name: THREE_TO_FIVE_WINTERS },
    { name: SIX_TO_TEN_WINTERS },
    { name: ELEVEN_TO_TWENTY_WINTERS },
    { name: MORE_THAN_TWENTY_WINTERS },
] as const

// Note that these keys are the same as the constants defined above, but React i18n won't allow dynamic key definitions.
export const experienceYearsMessages = defineMessages({
    first_winter: {
        id: 'UserResearchPanel.BackcountryActivities.first_winter',
        defaultMessage: 'This is/was my first winter.',
    },
    second_winter: {
        id: 'UserResearchPanel.BackcountryActivities.second_winter',
        defaultMessage: 'This is/was my second winter.',
    },
    three_to_five_winters: {
        id: 'UserResearchPanel.BackcountryActivities.three_to_five_winters',
        defaultMessage: '3-5 winters',
    },
    six_to_ten_winters: {
        id: 'UserResearchPanel.BackcountryActivities.six_to_ten_winters',
        defaultMessage: '6-10 winters',
    },
    eleven_to_twenty_winters: {
        id: 'UserResearchPanel.BackcountryActivities.eleven_to_twenty_winters',
        defaultMessage: '11-20 winters',
    },
    more_than_twenty_winters: {
        id: 'UserResearchPanel.BackcountryActivities.more_than_twenty_winters',
        defaultMessage: 'More than 20 winters',
    },
})
