import * as Products from './'

// IMPORTANT: the ids here must match the unique identifier in Prismic

export const iceClimbs = {
    type: 'FeatureCollection',
    features: [
        {
            type: 'Feature',
            properties: {
                name: 'Bourgeau Left',
                id: 'bourgeau-left',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.774838, 51.118243],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Bourgeau Right',
                id: 'bourgeau-right',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.76723, 51.12097],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Bridge Too Far',
                id: 'bridge-too-far',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.1973379, 50.86757083],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Carlsberg Column',
                id: 'carlsberg',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.5001, 51.377866],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Cascade Waterfall',
                id: 'cascade-waterfall',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.542866, 51.213468],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Guinness Gully',
                id: 'guinness',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.505531, 51.374474],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Kidd Falls',
                id: 'kidd-falls',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.192338, 50.871156],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'King Creek',
                id: 'king-creek',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.092875, 50.719077],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Louise Falls',
                id: 'louise-falls',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.241692, 51.408442],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Mt Kidd other climbs',
                id: 'other-mt-kidd',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.17499, 50.884345],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Murchison',
                id: 'murchison',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.6924, 51.920003],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Pilsner Pillar',
                id: 'pilsner',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.499068, 51.37931],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Polar Circus',
                id: 'polar-circus',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.986762, 52.139148],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Professor Falls',
                id: 'professor-falls',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.491965, 51.164155],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Ranger Creek',
                id: 'ranger-creek',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.291037, 50.762848],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Rogan’s Gully',
                id: 'rogans-gully',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.544898, 51.2118],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Tasting Fear',
                id: 'tasting-fear',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.2152259, 50.8638753],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Urs Hole',
                id: 'urs-hole',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.539352, 51.222118],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Bear Spirit',
                id: 'bear-spirit',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.663704, 51.18434],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Wedge Smear',
                id: 'wedge-smear',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.156555, 50.862291],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Whiteman Falls',
                id: 'whiteman-falls',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-115.077007, 50.676429],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Elliot Left-hand',
                id: 'elliotleft-hand',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.456194, 52.127579],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Nothing But the Breast',
                id: 'nothing-but-the-breast',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.435724, 52.09244],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Kitty Hawk',
                id: 'kitty-hawk',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.466615, 52.13807],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Suffer Machine to Man Yoga',
                id: 'suffer-machine-man-yoga',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.05935, 51.18701],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Nemesis to Killer Pillar',
                id: 'nemesis-killer-pillar',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.05298, 51.18478],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Monsieur Hulot to French Reality Sector',
                id: 'french-reality',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.06902, 51.19274],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Bison Falls and Transparent Fool',
                id: 'bison-falls-and-transparent-fool',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.67175, 51.8952],
            },
        },
        {
            type: 'Feature',
            properties: {
                name: 'Balfour Wall',
                id: 'balfour-wall',
                product: Products.ICE_CLIMB,
            },
            geometry: {
                type: 'Point',
                coordinates: [-116.68274, 51.89433],
            },
        },
    ],
}

export const ICE_CLIMB_SLUGS = iceClimbs.features.map(feature => feature.properties.id)
