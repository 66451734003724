import { useIntlMemo } from 'hooks/intl'

export default function useMenu() {
    return useIntlMemo(intl => ({
        children: [
            {
                title: intl.formatMessage({
                    defaultMessage: 'Learn',
                    description: 'Main menu',
                }),
                children: [
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'New to the Backcountry?',
                            description: 'Main menu',
                        }),
                        children: [
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Start Here',
                                    description: 'Main menu',
                                }),
                                href: '/start-here',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Essential Gear',
                                    description: 'Main menu',
                                }),
                                href: '/gear',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Training',
                                    description: 'Main menu',
                                }),
                                href: '/training',
                            },
                        ],
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'AvCan Training',
                            description: 'Main menu',
                        }),
                        href: '/training',
                        children: [
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Avalanche Skills Training 1',
                                    description: 'Main menu',
                                }),
                                href: '/training#ast1',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Avalanche Skills Training 2',
                                    description: 'Main menu',
                                }),
                                href: '/training#ast2',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Companion Rescue Skills',
                                    description: 'Main menu',
                                }),
                                href: '/training/#crs',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Managing Avalanche Terrain',
                                    description: 'Main menu',
                                }),
                                href: '/training#mat',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Find a Course',
                                    description: 'Main menu',
                                }),
                                href: '/training/courses',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Find a Provider',
                                    description: 'Main menu',
                                }),
                                href: '/training/providers',
                            },
                        ],
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Online Education',
                            description: 'Main menu',
                        }),
                        href: '/education',
                        children: [
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Avy Savvy',
                                    description: 'Main menu',
                                }),
                                href: intl.formatMessage({
                                    defaultMessage: 'https://avysavvy.ca',
                                    description: 'Main menu',
                                }),
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Rescue at Cherry Bowl',
                                    description: 'Main menu',
                                }),
                                href: 'https://avalanche.ca/cherry-bowl',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Glossary',
                                    description: 'Main menu',
                                }),
                                href: '/glossary',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'AvCan Webinars',
                                    description: 'Main menu',
                                }),
                                href: '/education/recorded-webinars',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'AvCan Videos',
                                    description: 'Main menu',
                                }),
                                href: 'https://vimeo.com/avalanchecanada',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Podcasts',
                                    description: 'Main menu',
                                }),
                                href: '/podcasts',
                            },
                        ],
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Youth Education',
                            description: 'Main menu',
                        }),
                        href: '/youth',
                        children: [
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Our Programs',
                                    description: 'Main menu',
                                }),
                                href: '/youth#programs',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Resources for Educators',
                                    description: 'Main menu',
                                }),
                                href: '/youth#youth-resources',
                            },
                        ],
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Visit Avy Savvy',
                            description: 'Main menu',
                        }),
                        description: intl.formatMessage({
                            defaultMessage: 'Take Our Online Tutorial',
                            description: 'Main menu',
                        }),
                        href: intl.formatMessage({
                            defaultMessage: 'https://avysavvy.ca',
                            description: 'Main menu',
                        }),
                        type: 'featured',
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Find a Course',
                            description: 'Main menu',
                        }),
                        description: intl.formatMessage({
                            defaultMessage: 'Looking for a Training Course?',
                            description: 'Main menu',
                        }),
                        href: '/training/courses',
                        type: 'featured',
                    },
                ],
            },
            {
                title: intl.formatMessage({
                    defaultMessage: 'Resources',
                    description: 'Main menu',
                }),
                children: [
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Mountain Information Network',
                            description: 'Main menu',
                        }),
                        href: '/mountain-information-network',
                        children: [
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'MIN 101',
                                    description: 'Main menu',
                                }),
                                href: '/mountain-information-network',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'List of Reports',
                                    description: 'Main menu',
                                }),
                                href: '/mountain-information-network/submissions',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Create a Report',
                                    description: 'Main menu',
                                }),
                                href: '/mountain-information-network/submit',
                            },
                        ],
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Planning',
                            description: 'Main menu',
                        }),
                        href: '/planning',
                        children: [
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Mountain Weather Forecast',
                                    description: 'Main menu',
                                }),
                                href: '/weather/forecast',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Forecasters’ Blogs',
                                    description: 'Main menu',
                                }),
                                href: '/blogs',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Trip Planner',
                                    description: 'Main menu',
                                }),
                                href: '/planning/trip-planner',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Dangerator',
                                    description: 'Main menu',
                                }),
                                href: '/dangerator',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Early Season Conditions',
                                    description: 'Main menu',
                                }),
                                href: '/early-season-conditions',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Spring Conditions',
                                    description: 'Main menu',
                                }),
                                href: '/spring-conditions',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Forecast Regions',
                                    description: 'Main menu',
                                }),
                                href: '/forecasts',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Weather Station List',
                                    description: 'Main menu',
                                }),
                                href: '/weather/stations',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Transceiver Interference',
                                    description: 'Main menu',
                                }),
                                href: '/transceiver-interference',
                            },
                        ],
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Resources by Activity',
                            description: 'Main menu',
                        }),
                        href: '/resources',
                        children: [
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Mountain Snowmobiling',
                                    description: 'Main menu',
                                }),
                                href: '/resources/mountain-snowmobiling',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Skiing and Snowboarding',
                                    description: 'Main menu',
                                }),
                                href: '/resources/skiing-and-snowboarding',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Snowshoeing',
                                    description: 'Main menu',
                                }),
                                href: '/resources/snowshoeing',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Ice Climbing',
                                    description: 'Main menu',
                                }),
                                href: '/resources/ice-climbing',
                            },
                        ],
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Archives',
                            description: 'Main menu',
                        }),
                        children: [
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Forecast Archive',
                                    description: 'Main menu',
                                }),
                                href: '/forecasts/archive',
                            },
                            {
                                title: intl.formatMessage({
                                    defaultMessage: 'Avalanche Fatality Database',
                                    description: 'Main menu',
                                }),
                                href: '/incidents',
                            },
                        ],
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Get the App',
                            description: 'Main menu',
                        }),
                        description: intl.formatMessage({
                            defaultMessage: 'Looking for Forecasts?',
                            description: 'Main menu',
                        }),
                        href: 'https://app.avalanche.ca',
                        type: 'featured',
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Recent MIN Reports',
                            description: 'Main menu',
                        }),
                        description: intl.formatMessage({
                            defaultMessage: 'Looking for Recent Conditions?',
                            description: 'Main menu',
                        }),
                        href: '/mountain-information-network/submissions',
                        type: 'featured',
                    },
                ],
            },
            {
                title: intl.formatMessage({
                    defaultMessage: 'Community',
                    description: 'Main menu',
                }),
                children: [
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Events',
                            description: 'Main menu',
                        }),
                        href: '/events',
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'News',
                            description: 'Main menu',
                        }),
                        href: '/news',
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'Ambassadors',
                            description: 'Main menu',
                        }),
                        href: '/ambassadors',
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'User Research Panel',
                            description: 'Main menu',
                        }),
                        href: '/user-research-panel',
                    },
                    {
                        title: intl.formatMessage({
                            defaultMessage: 'View Recent News',
                            description: 'Main menu',
                        }),
                        description: intl.formatMessage({
                            defaultMessage: 'The Latest from Us',
                            description: 'Main menu',
                        }),
                        href: '/news',
                        type: 'featured',
                    },
                ],
            },
            {
                title: intl.formatMessage({
                    defaultMessage: 'Store',
                    description: 'Main menu',
                }),
                href: 'https://store.avalanche.ca',
            },
            {
                title: intl.formatMessage({
                    defaultMessage: 'Avalanche Canada Foundation',
                    description: 'Main menu',
                }),
                href: 'https://foundation.avalanche.ca/',
            },
        ],
    }))
}
