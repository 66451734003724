import { ReactNode } from 'react'

import { Box, FormControl, FormHelperText } from '@mui/material'

import { QuestionText } from 'components/UserResearchPanel/CommonInputs/QuestionText'

type QuestionGroupProps = {
    question: ReactNode
    helperText?: ReactNode
    required?: boolean
    error?: boolean
    errorMessage?: string
    children: ReactNode
}

export const QuestionGroup = ({
    question,
    helperText,
    required,
    error,
    errorMessage,
    children,
}: QuestionGroupProps) => {
    return (
        <FormControl error={error} sx={styles.formControl}>
            <Box sx={styles.questionContainer}>
                <QuestionText required={required} variant="subquestion">
                    {question}
                </QuestionText>
            </Box>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            <Box sx={styles.wrapper}>
                {error && errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
                {children}
            </Box>
        </FormControl>
    )
}

const styles = {
    formControl: {
        width: '100%',
    },
    required: {
        color: 'red',
        marginLeft: '0.2rem',
    },
    questionContainer: {
        display: 'flex',
        alignItems: 'baseline',
    },
    wrapper: {
        marginLeft: '1rem',
        marginTop: '0.25em',
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '0.75rem',
    },
}
