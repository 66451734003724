import { APIClient } from 'services/api/APIClient'
import { PartialUserResearchPanelFormData, UserResearchPanelFormData } from 'stores/UserResearchPanelSignupStore'

// We cannot access the bearer token without using a hook, so it must be passed in here for POST/PUT/DELETE requests
export const postUserResearchPanelData = async (data: UserResearchPanelFormData, bearerToken: string) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    }

    return APIClient.post('/userResearchPanel', data, authHeader)
}

export const getUserResearchPanelData = (bearerToken: string) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    }

    return APIClient.get('/userResearchPanel', authHeader)
}

export const deleteUserResearchPanelData = async (bearerToken: string) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    }
    return APIClient.delete('/userResearchPanel', authHeader)
}

export const updateUserResearchPanelData = async (data: PartialUserResearchPanelFormData, bearerToken: string) => {
    const authHeader = {
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
    }
    return APIClient.put('/userResearchPanel', data, authHeader)
}
