import { defineMessages } from 'react-intl'

export const REGION_OPTIONS_YUKON_WHITE_PASS_HAINES_PASS = 'yukon_white_pass_haines_pass'
export const REGION_OPTIONS_NORTHWEST = 'northwest'
export const REGION_OPTIONS_SOUTHWEST = 'southwest'
export const REGION_OPTIONS_VANCOUVER_ISLAND = 'vancouver_island'
export const REGION_OPTIONS_COLUMBIAS = 'columbias'
export const REGION_OPTIONS_NORTH_ROCKIES = 'north_rockies'
export const REGION_OPTIONS_ROCKIES_PARKS = 'rockies_parks'
export const REGION_OPTIONS_SOUTH_ROCKIES = 'south_rockies'
export const REGION_OPTIONS_QUEBEC = 'quebec'
export const REGION_OPTIONS_NEWFOUNDLAND_AND_LABRADOR = 'newfoundland_and_labrador'
export const REGION_OPTIONS_ARCTIC = 'arctic'

export const REGION_OPTIONS = [
    REGION_OPTIONS_YUKON_WHITE_PASS_HAINES_PASS,
    REGION_OPTIONS_NORTHWEST,
    REGION_OPTIONS_SOUTHWEST,
    REGION_OPTIONS_VANCOUVER_ISLAND,
    REGION_OPTIONS_COLUMBIAS,
    REGION_OPTIONS_NORTH_ROCKIES,
    REGION_OPTIONS_ROCKIES_PARKS,
    REGION_OPTIONS_SOUTH_ROCKIES,
    REGION_OPTIONS_QUEBEC,
    REGION_OPTIONS_NEWFOUNDLAND_AND_LABRADOR,
    REGION_OPTIONS_ARCTIC,
] as const

// Note that these keys are the same as the constants defined above, but React i18n won't allow dynamic key definitions.
export const regionMessages = defineMessages({
    yukon_white_pass_haines_pass: {
        id: 'UserResearchPanel.BackcountryActivities.yukon_white_pass_haines_pass',
        defaultMessage: 'Yukon / White Pass / Haines Pass',
    },
    northwest: {
        id: 'UserResearchPanel.BackcountryActivities.northwest',
        defaultMessage: 'Northwest',
    },
    southwest: {
        id: 'UserResearchPanel.BackcountryActivities.southwest',
        defaultMessage: 'Southwest',
    },
    vancouver_island: {
        id: 'UserResearchPanel.BackcountryActivities.vancouver_island',
        defaultMessage: 'Vancouver Island',
    },
    columbias: {
        id: 'UserResearchPanel.BackcountryActivities.columbias',
        defaultMessage: 'Columbias',
    },
    north_rockies: {
        id: 'UserResearchPanel.BackcountryActivities.north_rockies',
        defaultMessage: 'North Rockies',
    },
    rockies_parks: {
        id: 'UserResearchPanel.BackcountryActivities.rockies_parks',
        defaultMessage: 'Rockies Parks',
    },
    south_rockies: {
        id: 'UserResearchPanel.BackcountryActivities.south_rockies',
        defaultMessage: 'South Rockies',
    },
    quebec: {
        id: 'UserResearchPanel.BackcountryActivities.quebec',
        defaultMessage: 'Quebec',
    },
    newfoundland_and_labrador: {
        id: 'UserResearchPanel.BackcountryActivities.newfoundland_and_labrador',
        defaultMessage: 'Newfoundland and Labrador',
    },
    arctic: {
        id: 'UserResearchPanel.BackcountryActivities.arctic',
        defaultMessage: 'Arctic',
    },
})
