import { defineMessages } from 'react-intl'

export const COURSE_COUNT_OPTIONS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'] // 1 to 10+ courses, could be a mix of AST/CAA Operations/webinars/etc.

export const NONE = 'none'
export const INDOOR_OR_LIVE_SEMINAR = 'indoorOrLiveSeminar'
export const INTRODUCTORY_RECREATIONAL_AVALANCHE_SAFETY_COURSE_WITH_A_FIELD_COMPONENT =
    'introductoryRecreationalAvalancheSafetyCourseWithAFieldComponent'
export const ADVANCED_RECREATIONAL_AVALANCHE_SAFETY_COURSE = 'advancedRecreationalAvalancheSafetyCourse'
export const AVALANCHE_TRAINING_AIMED_AT_AVALANCHE_PROFESSIONALS = 'avalancheTrainingAimedAtAvalancheProfessionals'
export const OTHER = 'other'

export const TRAINING_COURSE_OPTIONS = [
    NONE,
    INDOOR_OR_LIVE_SEMINAR,
    INTRODUCTORY_RECREATIONAL_AVALANCHE_SAFETY_COURSE_WITH_A_FIELD_COMPONENT,
    ADVANCED_RECREATIONAL_AVALANCHE_SAFETY_COURSE,
    AVALANCHE_TRAINING_AIMED_AT_AVALANCHE_PROFESSIONALS,
    OTHER,
] as const

export const trainingCourseMessages = defineMessages({
    none: {
        id: 'UserResearchPanel.TrainingCourses.none',
        defaultMessage: 'None',
        description: 'User Research Panel',
    },
    indoorOrLiveSeminar: {
        id: 'UserResearchPanel.TrainingCourses.indoorOrLiveSeminar',
        defaultMessage: 'Indoor/live online avalanche awareness seminar (e.g., evening seminar)',
        description: 'User Research Panel',
    },
    introductoryRecreationalAvalancheSafetyCourseWithAFieldComponent: {
        id: 'UserResearchPanel.TrainingCourses.introductoryRecreationalAvalancheSafetyCourseWithAFieldComponent',
        defaultMessage: 'Introductory recreational avalanche safety course with a field component (1-2 day course)',
        description: 'User Research Panel',
    },
    advancedRecreationalAvalancheSafetyCourse: {
        id: 'UserResearchPanel.TrainingCourses.advancedRecreationalAvalancheSafetyCourse',
        defaultMessage: 'Advanced recreational avalanche safety course (3-5 day course)',
        description: 'User Research Panel',
    },
    avalancheTrainingAimedAtAvalancheProfessionals: {
        id: 'UserResearchPanel.TrainingCourses.avalancheTrainingAimedAtAvalancheProfessionals',
        defaultMessage:
            'Avalanche training aimed at avalanche professionals (e.g., guides, mountain rescue, avalanche technicians)',
        description: 'User Research Panel',
    },
    other: {
        id: 'UserResearchPanel.TrainingCourses.other',
        defaultMessage: 'Other',
        description: 'User Research Panel',
    },
    '1': {
        id: 'UserResearchPanel.TrainingCourses.1',
        defaultMessage: '1',
        description: 'User Research Panel',
    },
    '2': {
        id: 'UserResearchPanel.TrainingCourses.2',
        defaultMessage: '2',
        description: 'User Research Panel',
    },
    '3': {
        id: 'UserResearchPanel.TrainingCourses.3',
        defaultMessage: '3',
        description: 'User Research Panel',
    },
    '4': {
        id: 'UserResearchPanel.TrainingCourses.4',
        defaultMessage: '4',
        description: 'User Research Panel',
    },
    '5': {
        id: 'UserResearchPanel.TrainingCourses.5',
        defaultMessage: '5',
        description: 'User Research Panel',
    },
    '6': {
        id: 'UserResearchPanel.TrainingCourses.6',
        defaultMessage: '6',
        description: 'User Research Panel',
    },
    '7': {
        id: 'UserResearchPanel.TrainingCourses.7',
        defaultMessage: '7',
        description: 'User Research Panel',
    },
    '8': {
        id: 'UserResearchPanel.TrainingCourses.8',
        defaultMessage: '8',
        description: 'User Research Panel',
    },
    '9': {
        id: 'UserResearchPanel.TrainingCourses.9',
        defaultMessage: '9',
        description: 'User Research Panel',
    },
    '10+': {
        id: 'UserResearchPanel.TrainingCourses.10+',
        defaultMessage: '10+',
        description: 'User Research Panel',
    },
})
