import { defineMessages } from 'react-intl'

export const FREQUENCY_OPTIONS_NEVER = 'never'
export const FREQUENCY_OPTIONS_RARELY = 'rarely'
export const FREQUENCY_OPTIONS_SOMETIMES = 'sometimes'
export const FREQUENCY_OPTIONS_OFTEN = 'often'
export const FREQUENCY_OPTIONS_ALWAYS = 'always'

export const FREQUENCY_OPTIONS = [
    FREQUENCY_OPTIONS_NEVER,
    FREQUENCY_OPTIONS_RARELY,
    FREQUENCY_OPTIONS_SOMETIMES,
    FREQUENCY_OPTIONS_OFTEN,
    FREQUENCY_OPTIONS_ALWAYS,
] as const

// Note that these keys are the same as the constants defined above, but React i18n won't allow dynamic key definitions.
export const frequencyMessages = defineMessages({
    never: {
        id: 'UserResearchPanel.BackcountryActivities.never',
        defaultMessage: 'Never',
    },
    rarely: {
        id: 'UserResearchPanel.BackcountryActivities.rarely',
        defaultMessage: 'Rarely',
    },
    sometimes: {
        id: 'UserResearchPanel.BackcountryActivities.sometimes',
        defaultMessage: 'Sometimes',
    },
    often: {
        id: 'UserResearchPanel.BackcountryActivities.often',
        defaultMessage: 'Often',
    },
    always: {
        id: 'UserResearchPanel.BackcountryActivities.always',
        defaultMessage: 'Always',
    },
})
